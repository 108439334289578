import {
  ExpressCheckoutElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { OnApproveData, OnApproveActions } from "@paypal/paypal-js";
import { PayPalButtons } from "@paypal/react-paypal-js";
import React, { useCallback, useEffect, useRef, useState } from "react";
import LoadingButton from "./LoadingButton";
import ShippingRates from "./ShippingRates";
import config from "../utils/config";
import ErrorAlert from "./ErrorAlert";
import { useCartContext } from "../CartContext";
import {
  ICheckout,
  ICustomerAddress,
  IShippingAddress,
  IShippingMethod,
} from "../types/types";
import AddressInput from "./AddressInput";
import * as yup from "yup";
import formatAddress from "../utils/formatAddress";
import AddressSelector from "./AddressSelector";
import TextInput from "./TextInput";
import { Transition } from "@headlessui/react";
import {
  StripeExpressCheckoutElementReadyEvent,
  StripeExpressCheckoutElementShippingAddressChangeEvent,
} from "@stripe/stripe-js";
import { postcodeToRegion } from "../utils/postcodeToRegion";
import trackingService from "../analytics/trackingService";
import formatPrice from "../utils/formatPrice";
import DiscountCodeForm from "./DiscountCodeForm";
import GiftCardForm from "./GiftCardForm";
import { ValidationError } from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import {
  OnShippingAddressChangeActions,
  OnShippingAddressChangeData,
} from "@paypal/paypal-js/types/components/buttons";
import { getShippingRatesForCheckout } from "../utils/getShippingRatesForCart";
import * as Sentry from "@sentry/react";
import classNames from "../utils/classNames";

const checkoutFormSchema = yup
  .object({
    email: yup.string().email().required(),
    shippingMethod: yup
      .object({
        handle: yup.string().required("Please select a shipping method"),
        groupId: yup.string(),
        price: yup.object({
            amount: yup.string().required("Please select a shipping method"),
            currencyCode: yup.string().required("Please select a shipping method"),
        }).required("Please select a shipping method"),
        title: yup.string().required("Please select a shipping method"),
      })
      .required("Please select a shipping method"),
  })
  .test("address", "Please enter a shipping address", function (value) {
    const { shippingAddress, customer } = (value as ICheckout) || {};

    if (!shippingAddress && !customer?.preferredAddress) {
      return this.createError({
        path: "shippingAddress",
        message: "Please enter a shipping address",
      });
    }

    return true;
  });

export interface IErrorState {
  error: string;
}
export function isErrorState(state: any): state is IErrorState {
  if (!state) {
    return false;
  }
  return (
    typeof state === "object" &&
    "error" in state &&
    typeof state.error === "string"
  );
}

const CheckoutForm = ({ onShowDetails }: { onShowDetails: () => void }) => {
  const checkoutFormRef = useRef<null | HTMLFormElement>(null);
  const {
    checkout,
    checkoutTotals,
    cartLoading,
    totalsLoading,
    updateShippingMethod,
    updateShippingAddress,
    updateCustomerPreferredAddress,
    updateEmail,
    removeUserFromCart,
    toggleEmailMarketingOptIn,
  } = useCartContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [email, setEmail] = useState("");
  const [manualShippingAddress, setManualShippingAddress] =
    useState<Partial<ICustomerAddress> | null>(
      checkout?.shippingAddress ?? { country: "NZ" },
    );
  const [manualBillingAddress, setManualBillingAddress] =
    useState<Partial<ICustomerAddress> | null>(null);
  const [useShippingAddressForBilling, setUseShippingAddressForBilling] =
    useState(true);
  const [customerAddress, setCustomerAddress] =
    useState<ICustomerAddress | null>(null);
  const [selectedShippingMethod, setSelectedShippingMethod] =
    useState<IShippingMethod | null>(null);
  const [showAddressSelector, setShowAddressSelector] = useState(false);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [formErrors, setFormErrors] = useState<Record<string, string[]>>({});
  const [enabledPaymentMethods, setEnabledPaymentMethods] = useState<string[]>(
    [],
  );
  const [paymentMethod, setPaymentMethod] = useState("stripe");
  const [expressElementsLoading, setExpressElementsLoading] = useState(true);

  const elements = useElements();
  const stripe = useStripe();
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!checkout) {
      return;
    }
    setError(null);

    if (isErrorState(state)) {
      setError(state.error);
    }

    if (checkout.email) {
      setEmail(checkout.email);
      Sentry.setUser({
        email: checkout.email,
      });
    }

    if (!checkout.shippingAddress && !checkout.customer) {
      setShowAddressForm(true);
    } else if (checkout.shippingAddress) {
      setManualShippingAddress(checkout.shippingAddress);
      setShowAddressForm(true);
    } else {
      const customerShippingAddress =
        checkout.customer?.preferredAddress ?? null;
      setCustomerAddress(customerShippingAddress);
      if (!customerShippingAddress) {
        setShowAddressForm(true);
      }
    }
  }, [checkout, state]);

  useEffect(() => {
    if (email === null || email === "") {
      return;
    }
    const delayInputTimeoutId = setTimeout(() => {
      checkoutFormSchema
        .validateAt("email", { email })
        .then(() => {
          updateEmail(email).then(() => {});
        })
        .catch((error) => {
          setFormErrors({
            email: error.message,
          });
        });
    }, 1000);
    return () => clearTimeout(delayInputTimeoutId);
    /* TODO fix this */
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [email]);
  /* eslint-enable react-hooks/exhaustive-deps */

  function handleEmailAddressChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) {
    const updatedFormErrors = { ...formErrors };
    delete updatedFormErrors.email;
    setFormErrors(updatedFormErrors);
    setEmail(event.target.value);
  }

  const handleShippingAddressChange = useCallback(
    async (shippingAddress: IShippingAddress | null) => {
      setManualShippingAddress(shippingAddress);
      setFormErrors({});
      try {
        Sentry.addBreadcrumb({
          category: "checkout",
          message: "Shipping address updated",
        });
        Sentry.setContext("checkout", checkout);
        await updateShippingAddress(shippingAddress);
        await trackingService.trackEvent("add_shipping_info", {
          checkout: checkout,
          totals: checkoutTotals,
        });
      } catch (e) {
        setError(e.message);
      }
      /* eslint-disable react-hooks/exhaustive-deps */
    },
    [checkout?.email, checkout?.phone],
  );
  /* eslint-enable react-hooks/exhaustive-deps */

  const handleBillingAddressChange = useCallback(
    async (billingAddress: IShippingAddress | null) => {
      setManualBillingAddress(billingAddress);
    },
    [],
  );

  async function validateCheckout() {
    if (!checkout) {
      throw new Error("No checkout found");
    }
    await checkoutFormSchema.validate(checkout, { abortEarly: false });
  }

  async function handleGiftCardOnlyPayment() {
    if (!checkout || !checkout.giftCard) {
      return false;
    }
    const cartId = checkout.cartId.replace("gid://shopify/Cart/", "");
    navigate(`/checkout/confirm/${cartId}&gc=1`);
  }

  async function handlePaymentSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    setError(null);
    if (!checkout) {
      throw new Error("Stripe not initialised");
    }
    setLoading(true);

    try {
      await validateCheckout();
      Sentry.addBreadcrumb({
        category: "checkout",
        message: "Payment attempt",
      });
      Sentry.setContext("checkout", checkout);
      await trackingService.trackEvent("add_payment_info", {
        checkout: checkout,
        totals: checkoutTotals,
      });
    } catch (e) {
      if (checkoutFormRef.current) {
        checkoutFormRef.current.scrollIntoView({ behavior: "smooth" });
      }
      const validationErrors: Record<string, string[]> = {};
      e.inner.forEach((validationError: ValidationError) => {
        if (validationError.path) {
          validationErrors[validationError.path] = [validationError.message];
        }
      });
      setFormErrors({
        ...formErrors,
        ...validationErrors,
      });
      console.error({ formErrors, validationErrors });
      setError("Please complete all required fields and try again.");
      setLoading(false);
      return;
    }

    try {
      if (checkout.giftCard && checkoutTotals?.amountDueInCents === 0) {
        await handleGiftCardOnlyPayment();
        return;
      }

      if (paymentMethod === "stripe") {
        await handleStripePayment(checkout);
      }

      if (paymentMethod === "windcave" || paymentMethod === "farmlands") {
        await handleWindcavePayment(checkout);
      }
      if (paymentMethod === "laybuy") {
        await handleLaybuyPayment(checkout);
      }
      if (paymentMethod === "zip") {
        await handleZipPayment(checkout);
      }
      if (paymentMethod === "gem") {
        await handleGemPayment(checkout);
      }
      if (paymentMethod === "poli") {
        await handlePoliPayment(checkout);
      }
    } catch (error) {
      console.error("Error processing payment", error);
      setError(
        `Error processing payment with ${paymentMethod}: ${error.message} Please try again or contact support if you continue to have issues`,
      );
    } finally {
      setLoading(false);
    }
  }

  async function handleStripePayment(checkout: ICheckout) {
    if (!stripe || !elements || !checkout) {
      throw new Error("Stripe not initialised");
    }
    const { error: submitError } = await elements.submit();
    if (submitError) {
      throw new Error(
        submitError.message ??
          "Something went wrong with your payment, please try again or contact support",
      );
    }

    const sessionResponse = await fetch(
      `${config.checkoutUrl}/api/createSession`,
      {
        method: "POST",
        body: JSON.stringify({
          cartId: checkout.cartId,
          provider: "stripe",
        }),
      },
    );

    const { sessionData } = await sessionResponse.json();
    const cartId = checkout.cartId.replace("gid://shopify/Cart/", "");
    const shippingAddress =
      checkout.shippingAddress ?? checkout.customer?.preferredAddress;
    const billingAddress = shippingAddress;

    // Confirm the PaymentIntent using the details collected by the Payment Element
    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret: sessionData.sessionId,
      confirmParams: {
        return_url: `${config.checkoutUrl}/checkout/confirm/${cartId}`,
        shipping: {
          name: `${shippingAddress?.firstName} ${shippingAddress?.lastName}`,
          phone: shippingAddress?.phone ?? undefined,
          address: {
            line1: shippingAddress?.address1 ?? "",
            line2: shippingAddress?.address2 ?? undefined,
            city: shippingAddress?.city,
            state: shippingAddress?.province ?? "",
            postal_code: shippingAddress?.zip,
            country: config.countryCode,
          },
        },
        payment_method_data: {
          billing_details: {
            name: `${checkout.shippingAddress?.firstName} ${checkout.shippingAddress?.lastName}`,
            email: checkout.email ?? undefined,
            phone: billingAddress?.phone ?? undefined,
            address: {
              line1: billingAddress?.address1,
              line2: billingAddress?.address2 ?? undefined,
              city: billingAddress?.city,
              state: billingAddress?.province ?? "",
              postal_code: billingAddress?.zip,
              country: "NZ",
            },
          },
        },
      },
    });

    if (error) {
      throw new Error(
        error.message ??
          "Something went wrong with your payment, please try again or contact support",
      );
    }
  }

  async function handleStripeExpressPayment(checkout: ICheckout) {
    if (!stripe || !elements || !checkout) {
      throw new Error("Stripe not initialised");
    }
    const { error: submitError } = await elements.submit();
    if (submitError) {
      throw new Error(
        submitError.message ??
        "Something went wrong with your payment, please try again or contact support",
      );
    }

    const sessionResponse = await fetch(
      `${config.checkoutUrl}/api/createSession`,
      {
        method: "POST",
        body: JSON.stringify({
          cartId: checkout.cartId,
          provider: "stripe",
        }),
      },
    );

    const { sessionData } = await sessionResponse.json();
    const cartId = checkout.cartId.replace("gid://shopify/Cart/", "");

    // Confirm the PaymentIntent using the details collected by the Payment Element
    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret: sessionData.sessionId,
      confirmParams: {
        return_url: `${config.checkoutUrl}/checkout/confirm/${cartId}`,
      }
    });

    if (error) {
      throw new Error(
        error.message ??
        "Something went wrong with your payment, please try again or contact support",
      );
    }
  }

  async function handleLaybuyPayment(checkout: ICheckout) {
    const sessionResponse = await fetch(
      `${config.checkoutUrl}/api/createSession`,
      {
        method: "POST",
        body: JSON.stringify({
          cartId: checkout.cartId.replace("gid://shopify/Cart/", ""),
          provider: "laybuy",
        }),
      },
    );

    if (!sessionResponse.ok) {
      throw new Error("Unable to create Laybuy payment session");
    }
    const { sessionData } = await sessionResponse.json();
    window.location.href = sessionData.redirectUrl;
  }

  async function handlePoliPayment(checkout: ICheckout) {
    const sessionResponse = await fetch(
      `${config.checkoutUrl}/api/createSession`,
      {
        method: "POST",
        body: JSON.stringify({
          cartId: checkout.cartId.replace("gid://shopify/Cart/", ""),
          provider: "poli",
        }),
      },
    );

    if (!sessionResponse.ok) {
      throw new Error("Unable to create POLi payment session");
    }
    const { sessionData } = await sessionResponse.json();
    window.location.href = sessionData.redirectUrl;
  }

  async function handleZipPayment(checkout: ICheckout) {
    const sessionResponse = await fetch(
      `${config.checkoutUrl}/api/createSession`,
      {
        method: "POST",
        body: JSON.stringify({
          cartId: checkout.cartId.replace("gid://shopify/Cart/", ""),
          provider: "zip",
        }),
      },
    );

    if (!sessionResponse.ok) {
      throw new Error("Unable to create zip payment session");
    }

    const { sessionData } = await sessionResponse.json();
    window.location.href = sessionData.redirectUrl;
  }

  async function handleGemPayment(checkout: ICheckout) {
    const sessionResponse = await fetch(
      `${config.checkoutUrl}/api/createSession`,
      {
        method: "POST",
        body: JSON.stringify({
          cartId: checkout.cartId.replace("gid://shopify/Cart/", ""),
          provider: "gem",
        }),
      },
    );

    if (!sessionResponse.ok) {
      throw new Error("Unable to create gem payment session");
    }

    const { sessionData } = await sessionResponse.json();
    window.location.href = sessionData.redirectUrl;
  }

  async function handleWindcavePayment(checkout: ICheckout) {
    const sessionResponse = await fetch(
      `${config.checkoutUrl}/api/createSession`,
      {
        method: "POST",
        body: JSON.stringify({
          cartId: checkout.cartId.replace("gid://shopify/Cart/", ""),
          provider: "windcave",
        }),
      },
    );

    if (!sessionResponse.ok) {
      throw new Error("Unable to create Windcave payment session");
    }

    const { sessionData } = await sessionResponse.json();
    window.location.href = sessionData.redirectUrl;
  }

  async function handlePaypalPayment(checkout: ICheckout): Promise<string> {
    const sessionResponse = await fetch(
      `${config.checkoutUrl}/api/createSession`,
      {
        method: "POST",
        body: JSON.stringify({
          cartId: checkout.cartId.replace("gid://shopify/Cart/", ""),
          provider: "paypal",
        }),
      },
    );

    if (!sessionResponse.ok) {
      throw new Error("Unable to create Paypal payment session");
    }

    const { sessionData } = await sessionResponse.json();
    return sessionData.sessionId;
  }

  async function handleShippingMethodSelected(shippingMethod: IShippingMethod) {
    setSelectedShippingMethod(shippingMethod);
    await updateShippingMethod(shippingMethod);
  }

  async function handleExpressShippingAddressChange({
    address,
    resolve,
    reject,
  }: StripeExpressCheckoutElementShippingAddressChangeEvent) {
    try {
      if (address.country === "NZ" && address.state === "") {
        const region = postcodeToRegion(address.postal_code);
        address.state = region.region;
      }
      await updateShippingAddress({
        firstName: "",
        lastName: "",
        address1: "",
        address2: "",
        city: address.city,
        province: address.state,
        provinceCode: null,
        zip: address.postal_code,
        country: address.country,
        phone: "",
      });
      const availableShippingMethods = checkout?.availableShippingMethods ?? [];
      if (availableShippingMethods.length > 0) {
        resolve({
          shippingRates: checkout?.availableShippingMethods.map((method) => {
            return {
              id: method.handle,
              amount: Math.ceil(parseFloat(method.price.amount) * 100),
              displayName: method.title,
            };
          }),
        });
      } else {
        console.error("No shipping methods available for address", address);
        reject();
      }
    } catch (error) {
      console.error(error, address);
      reject();
    }
  }

  async function handlePaypalShippingAddress(
    data: OnShippingAddressChangeData,
    actions: OnShippingAddressChangeActions,
  ) {
    if (!checkout || !checkoutTotals) {
      return;
    }
    if (data.shippingAddress.countryCode !== config.countryCode) {
      return actions.reject();
    }
    try {
      const internalCheckout = { ...checkout };
      const region = postcodeToRegion(data.shippingAddress.postalCode);
      internalCheckout.shippingAddress = {
        firstName: "",
        lastName: "",
        address1: "",
        address2: null,
        city: data.shippingAddress?.city ?? "",
        province: region.region,
        provinceCode: region.code,
        zip: data.shippingAddress?.postalCode ?? "",
        country: data.shippingAddress?.countryCode ?? "",
        phone: "",
      };
      const rates = await getShippingRatesForCheckout(internalCheckout);
      if (Array.isArray(rates)) {
        await fetch("/api/paypalUpdateOrder", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            orderId: data.orderID,
            cartId: checkout?.cartId.replace("gid://shopify/Cart/", ""),
          }),
        });
      }
    } catch (e) {
      console.error(e);
      setError("Could not fetch shipping methods for Paypal");
      return actions.reject();
    }
  }

  const handlePaypalCapture = async (
    data: OnApproveData,
    actions: OnApproveActions,
  ) => {
    try {
      const response = await fetch("/api/confirmPayment", {
        method: "POST",
        body: JSON.stringify({
          provider: "paypal",
          externalId: data.orderID,
          cartId: checkout?.cartId.replace("gid://shopify/Cart/", ""),
        }),
      });

      const orderData = await response.json();

      const errorDetail = orderData?.details?.[0];

      if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
        // Recoverable INSTRUMENT_DECLINED -> call actions.restart()
        // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
        return actions.restart();
      } else if (errorDetail) {
        console.error(`Paypal failed with: ${errorDetail.description}`, {
          orderData,
          errorDetail,
        });
        throw new Error(
          `Could not complete your payment with PayPal: ${errorDetail.description}`,
        );
      } else {
        const cartId = checkout?.cartId.replace("gid://shopify/Cart/", "");
        Sentry.addBreadcrumb({
          category: "checkout",
          message: "Purchase completed",
        });
        Sentry.setContext("checkout", checkout);
        await trackingService.trackEvent("purchase", {
          checkout: checkout,
          totals: checkoutTotals,
        });
        navigate(`/checkout/complete/${cartId}&orderId=${data.orderID}`, {
          replace: true,
        });
      }
    } catch (error) {
      console.error(error);
      setError(`Could not complete your payment with PayPal: ${error}`);
    }
  };

  async function handleSelectCustomerAddress(address: ICustomerAddress) {
    if (!address.province && !address.provinceCode) {
      const region = postcodeToRegion(address.zip);
      address.province = region.region;
      address.provinceCode = region.code;
    }
    await updateCustomerPreferredAddress(address);
  }

  async function handleLogout() {
    await removeUserFromCart();
    setEmail("");
    setCustomerAddress(null);
    setSelectedShippingMethod(null);
  }

  if (!checkout) {
    return null;
  }

  function handleAddNewCustomerAddress() {
    setCustomerAddress(null);
    setShowAddressForm(true);
  }

  return (
    <form onSubmit={handlePaymentSubmit} ref={checkoutFormRef}>
      <section className="mx-auto max-w-lg lg:max-w-none">
        {expressElementsLoading && (
          <div className="flex flex-col md:flex-row gap-3 animate-pulse">
            <div className="w-full">
              <span className="w-full h-12 block bg-gray-200 rounded-full"></span>
            </div>
            <div className="w-full">
              <span className="w-full h-12 block bg-gray-200 rounded-full"></span>
            </div>
            <div className="w-full">
              <span className="w-full h-12 block bg-gray-200 rounded-full"></span>
            </div>
          </div>
        )}
        <div className="flex flex-col-reverse md:flex-row">
          <ExpressCheckoutElement
            className={classNames(
              enabledPaymentMethods.length === 2 ? "md:w-[65%]" : "",
              enabledPaymentMethods.length === 1 ? "md:w-[50%]" : "",
              "w-full",
            )}
            options={{
              layout: {
                maxColumns: 2,
                maxRows: 2,
              },
              buttonTheme: {
                applePay: "black",
              },
              buttonType: {
                googlePay: "plain",
              },
              buttonHeight: 50,
            }}
            onClick={({ resolve }) => {
              resolve({
                emailRequired: true,
                shippingAddressRequired: true,
                allowedShippingCountries: ["NZ"],
                shippingRates: [
                  {
                    id: "standard",
                    amount: 900,
                    displayName: "Standard Shipping",
                  },
                ],
              });
            }}
            onConfirm={() => {
              handleStripeExpressPayment(checkout);
            }}
            onReady={(event: StripeExpressCheckoutElementReadyEvent) => {
              setExpressElementsLoading(false);
              if (event.availablePaymentMethods) {
                const enabledMethods = Object.keys(
                  event.availablePaymentMethods,
                ).filter(
                  (key) => (event.availablePaymentMethods as any)[key] === true,
                );
                setEnabledPaymentMethods(enabledMethods);
              }
            }}
            onShippingRateChange={(event) => {
              // const selectedShippingMethod = checkout?.availableShippingMethods.find((method) => {
              //
              // });
              // updateShippingMethod()
            }}
            onShippingAddressChange={handleExpressShippingAddressChange}
          />
          {!expressElementsLoading && (
            <div
              className={classNames(
                enabledPaymentMethods.length === 2 ? "md:w-[32%]" : "",
                enabledPaymentMethods.length === 1 ? "md:w-[50%]" : "",
                "w-full md:px-3",
              )}
            >
              <PayPalButtons
                style={{
                  shape: "pill",
                  layout: "vertical",
                  height: 50,
                }}
                createOrder={async () => await handlePaypalPayment(checkout)}
                onApprove={handlePaypalCapture}
                onShippingAddressChange={handlePaypalShippingAddress}
              />
            </div>
          )}
        </div>
        <div className="relative mt-8">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-200" />
          </div>
          <div className="relative flex justify-center mb-4">
            <span className="bg-white px-4 text-sm font-medium text-gray-500">
              Or pay another way
            </span>
          </div>
        </div>
      </section>
      <section className="mx-auto max-w-lg lg:max-w-none">
        <section aria-labelledby="contact-info-heading">
          {checkout?.customer?.email && (
            <div className="p-3 bg-gray-50 rounded-2xl border border-gray-200">
              <span className="text-gray-900 block text-sm font-medium">
                {checkout?.customer?.email}
              </span>
              <button
                onClick={handleLogout}
                type="button"
                className="mt-2 text-sm underline text-blue-500"
                disabled={cartLoading}
              >
                Logout
              </button>
            </div>
          )}
          {!checkout?.customer?.email && (
            <>
              <TextInput
                name="email"
                type="email"
                label="Email address"
                placeholder="Email address"
                autoComplete="email"
                value={email}
                errors={formErrors}
                onChange={handleEmailAddressChange}
              />
              <div className="mt-2 relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    id="marketing_opt_in"
                    name="marketing_opt_in"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-gray-600 focus:ring-gray-600"
                    checked={checkout?.emailMarketingOptIn}
                    onChange={() => toggleEmailMarketingOptIn()}
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor="marketing_opt_in"
                    className="font-normal text-gray-900"
                  >
                    Subscribe to receive exclusive offers and news via email.
                  </label>
                </div>
              </div>
            </>
          )}
        </section>

        <section aria-labelledby="shipping-heading" className="relative mt-6">
          <h2
            id="delivery-heading"
            className="block text-md font-medium leading-6 text-gray-600"
          >
            Shipping address
          </h2>
          <div
            className={`transition duration-300 ${showAddressForm ? "opacity-100" : "h-0 opacity-0 -z-1 pointer-events-none"}`}
          >
            <AddressInput
              value={manualShippingAddress}
              onChange={handleShippingAddressChange}
              hideOptIn={true}
              errors={formErrors}
            />
          </div>
          {!checkout.shippingAddress &&
            checkout.customer &&
            checkout.customer.preferredAddress &&
            customerAddress &&
            !showAddressForm && (
              <div className="relative min-h-[100px]">
                <div
                  className={`absolute p-3 bg-gray-50 transition duration-300 rounded-2xl border border-gray-200 ${!showAddressSelector ? "opacity-100" : "opacity-0 -z-1 pointer-events-none"}`}
                >
                  <span className="text-gray-900 block text-sm font-medium">
                    {formatAddress(checkout.customer.preferredAddress)}
                  </span>
                  <button
                    type="button"
                    className="mt-2 text-sm underline text-blue-500"
                    onClick={() => setShowAddressSelector(true)}
                  >
                    Change
                  </button>
                </div>
                <Transition
                  show={showAddressSelector}
                  enter="transition-opacity duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-75"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <AddressSelector
                    onChange={handleSelectCustomerAddress}
                    disabled={cartLoading}
                    value={
                      checkout.customer.preferredAddress ??
                      checkout.customer.addresses[0]
                    }
                    addresses={checkout.customer.addresses}
                  />
                  <button
                    type="button"
                    className="mt-2 text-blue-400 underline font-medium"
                    onClick={handleAddNewCustomerAddress}
                  >
                    Add new address
                  </button>
                </Transition>
              </div>
            )}
        </section>
        <section aria-labelledby="delivery-heading" className="mt-6">
          <h2
            id="delivery-heading"
            className="block text-md font-medium leading-6 text-gray-600"
          >
            Shipping method
          </h2>
          {checkout.availableShippingMethods.length > 0 && (
            <ShippingRates
              rates={checkout.availableShippingMethods}
              disabled={cartLoading || totalsLoading}
              selected={selectedShippingMethod ?? checkout.shippingMethod}
              onShippingRateSelected={handleShippingMethodSelected}
            />
          )}
          {checkout.availableShippingMethods.length === 0 && (
            <div className="p-2 mt-2">
              <span className="text-sm text-gray-400">
                Enter shipping address to see available rates
              </span>
            </div>
          )}
        </section>
        <section>
          <div className="mt-2 relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                id="use_shipping_address_for_billing_address"
                name="use_shipping_address_for_billing_address"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-gray-600 focus:ring-gray-600"
                checked={useShippingAddressForBilling}
                onChange={(event) =>
                  setUseShippingAddressForBilling(!useShippingAddressForBilling)
                }
              />
            </div>
            <div className="ml-3 text-sm leading-6">
              <label
                htmlFor="use_shipping_address_for_billing_address"
                className="font-normal text-gray-900"
              >
                Use shipping address as billing address
              </label>
            </div>
          </div>
          {!useShippingAddressForBilling && (
            <div className="mt-2">
              <h2
                id="delivery-heading"
                className="block text-md font-medium leading-6 text-gray-600"
              >
                Billing address
              </h2>
              <div
                className={`transition duration-300 ${!customerAddress ? "opacity-100" : "h-0 opacity-0 -z-1 pointer-events-none"}`}
              >
                <AddressInput
                  value={manualBillingAddress}
                  onChange={handleBillingAddressChange}
                  hideOptIn={true}
                />
              </div>
            </div>
          )}
        </section>
        {checkoutTotals && checkoutTotals.amountDueInCents > 0 && (
          <section
            aria-labelledby="payment-heading"
            className="mt-6 border-gray-200"
          >
            <h2
              id="delivery-heading"
              className="block text-md font-medium leading-6 text-gray-600"
            >
              Payment method
            </h2>
            <div className="w-full pt-8">
              <div className="mx-auto w-full rounded-full bg-white">
                <div>
                  <div className="flex gap-2 justify-flex-start py-6">
                    <button
                      onClick={() => setPaymentMethod("farmlands")}
                      type="button"
                      className={`${paymentMethod === "farmlands" ? "border-2 border-black text-black" : "text-gray-500"} text-left p-3 w-full border hover:text-black rounded-3xl text-xs font-bold`}
                    >
                      <img
                        alt="pay with Farmlands Card"
                        src="/logos/farmlands.png"
                        className="h-4 mb-1 rounded-md"
                      />
                      Farmlands
                    </button>
                    <button
                      onClick={() => setPaymentMethod("zip")}
                      type="button"
                      className={`${paymentMethod === "zip" ? "border-2 border-black text-black" : "text-gray-500"} text-left p-3 w-full border hover:text-black rounded-3xl text-xs font-bold`}
                    >
                      <img
                        alt="pay with Zip"
                        src="/logos/zip.svg"
                        className="h-3 mb-2"
                      />
                      Zip
                    </button>
                    <button
                      onClick={() => setPaymentMethod("gem")}
                      type="button"
                      className={`${paymentMethod === "gem" ? "border-2 border-black text-black" : "text-gray-500"} text-left p-3 w-full border hover:text-black rounded-3xl text-xs font-bold`}
                    >
                      <img
                        alt="pay with Gem"
                        src="/logos/gem.png"
                        className="h-4 mb-1 rounded-md"
                      />
                      Gem
                    </button>
                    <button
                      onClick={() => setPaymentMethod("poli")}
                      type="button"
                      className={`${paymentMethod === "poli" ? "border-2 border-black text-black" : "text-gray-500"} text-left p-3 w-full border hover:text-black rounded-3xl text-xs font-bold`}
                    >
                      <img
                        alt="pay with POLi"
                        src="/logos/poli.svg"
                        className="h-4 mb-1 rounded-md"
                      />
                      POLi
                    </button>
                  </div>
                  {paymentMethod !== "stripe" && (
                    <div className="flex gap-2 justify-flex-start pb-6">
                      <button
                        type="button"
                        onClick={() => setPaymentMethod("stripe")}
                        className="text-left p-3 w-full border text-gray-500 hover:text-black rounded-3xl text-xs font-bold"
                      >
                        <img
                          alt="pay with Card"
                          src="/logos/card.svg"
                          className="h-4 mb-1"
                        />
                        Card
                      </button>
                      {checkoutTotals.amountDueInCents < 200000 && (
                        <button
                          type="button"
                          onClick={() => setPaymentMethod("stripe")}
                          className="text-left p-3 w-full border text-gray-500 hover:text-black rounded-3xl text-xs font-bold"
                        >
                          <svg
                            className="h-4 mb-1 rounded-md"
                            viewBox="0 0 16 16"
                            fill="none"
                            role="presentation"
                            focusable="false"
                          >
                            <path
                              d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Z"
                              fill="#B2FCE4"
                            ></path>
                            <path
                              d="m12.563 5.187-1.477-.845-1.498-.859c-.99-.567-2.228.146-2.228 1.29v.192a.29.29 0 0 0 .15.256l.695.397a.288.288 0 0 0 .431-.252V4.91c0-.226.243-.367.44-.256l1.366.786 1.362.78a.293.293 0 0 1 0 .509l-1.362.781-1.366.786a.294.294 0 0 1-.44-.257v-.226c0-1.144-1.238-1.861-2.228-1.29l-1.494.863-1.478.846a1.49 1.49 0 0 0 0 2.582l1.478.845 1.498.859c.99.567 2.228-.146 2.228-1.29v-.192a.29.29 0 0 0-.15-.256l-.695-.397a.288.288 0 0 0-.431.252v.457a.294.294 0 0 1-.44.256l-1.366-.786-1.362-.78a.293.293 0 0 1 0-.509l1.362-.781 1.366-.786c.197-.11.44.03.44.257v.226c0 1.144 1.238 1.861 2.228 1.289l1.499-.858 1.477-.845c.99-.577.99-2.015-.005-2.587Z"
                              fill="#000"
                            ></path>
                          </svg>
                          Afterpay
                        </button>
                      )}
                      <button
                        type="button"
                        onClick={() => setPaymentMethod("stripe")}
                        className="text-left p-3 w-full border text-gray-500 hover:text-black rounded-3xl text-xs font-bold"
                      >
                        <img
                          alt="pay with Klarna"
                          src="/logos/klarna.svg"
                          className="h-4 mb-1"
                        />
                        Klarna
                      </button>
                      <button
                        type="button"
                        onClick={() => setPaymentMethod("stripe")}
                        className="text-left p-3 w-full border text-gray-500 hover:text-black rounded-3xl text-xs font-bold"
                      >
                        <img
                          alt="pay with Alipay"
                          src="/logos/alipay.svg"
                          className="h-4 mb-1"
                        />
                        Alipay
                      </button>
                    </div>
                  )}
                </div>
                {paymentMethod === "stripe" && (
                  <PaymentElement
                    onChange={(event: unknown) => {
                      setError(null);
                    }}
                    options={{
                      layout: {
                        type: "tabs",
                        defaultCollapsed: false,
                        radios: true,
                        spacedAccordionItems: false,
                      },
                    }}
                  />
                )}
                <Transition
                  show={paymentMethod === "windcave"}
                  enter="transition-opacity duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-75"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="transition min-h-[100px] border border-gray-300 rounded-3xl p-4">
                    <div className="font-bold flex align-center">
                      <img
                        alt="Windcave logo"
                        src="/logos/windcave.svg"
                        className="h-4 mb-1 mr-2"
                      />{" "}
                      Pay with Windcave
                    </div>
                    <p className="text-sm">
                      Click pay to proceed to Windcave payment form
                    </p>
                  </div>
                </Transition>
                <Transition
                  show={paymentMethod === "farmlands"}
                  enter="transition-opacity duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-0"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="transition min-h-[100px] border border-gray-300 rounded-3xl p-4">
                    <div className="font-bold flex align-center">
                      <img
                        alt="Farmlands logo"
                        src="/logos/farmlands.png"
                        className="h-4 mb-1 mr-2"
                      />{" "}
                      Pay with Farmlands card
                    </div>
                    <p className="text-sm">
                      Click pay to proceed to Farmlands payment page
                    </p>
                    <p className="text-sm mt-2">
                      The Farmlands exclusive rebate will be applied on your
                      statement.
                    </p>
                  </div>
                </Transition>
                <Transition
                  show={paymentMethod === "zip"}
                  enter="transition-opacity duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-0"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="transition min-h-[100px] border border-gray-300 rounded-3xl p-4">
                    <div className="font-bold flex align-center">
                      <img
                        alt="Zip logo"
                        src="/logos/zip.svg"
                        className="h-4 mb-1 mr-2"
                      />{" "}
                      Pay with Zip
                    </div>
                    <p className="text-sm">
                      Click pay to proceed to Zip payment form
                    </p>
                  </div>
                </Transition>
                <Transition
                  show={paymentMethod === "poli"}
                  enter="transition-opacity duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-0"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="transition min-h-[100px] border border-gray-300 rounded-3xl p-4">
                    <div className="font-bold flex align-center">
                      <img
                        alt="POLi logo"
                        src="/logos/poli.svg"
                        className="h-4 mb-1 mr-2"
                      />{" "}
                      Pay with POLi
                    </div>
                    <p className="text-sm">
                      Click pay to proceed to POLi payment form
                    </p>
                  </div>
                </Transition>
                <Transition
                  show={paymentMethod === "laybuy"}
                  enter="transition-opacity duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-0"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="transition min-h-[100px] border border-gray-300 rounded-3xl p-4">
                    <div className="font-bold flex align-center">
                      <img
                        alt="Laybuy logo"
                        src="/logos/laybuy.png"
                        className="h-4 mb-1 mr-2"
                      />{" "}
                      Pay with Laybuy
                    </div>
                    <p className="text-sm">
                      Click pay to proceed to Laybuy payment form
                    </p>
                  </div>
                </Transition>
                <Transition
                  show={paymentMethod === "gem"}
                  enter="transition-opacity duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-75"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="transition min-h-[100px] border border-gray-300 rounded-3xl p-4">
                    <div className="font-bold flex align-center">
                      <img
                        alt="Gem logo"
                        src="/logos/gem.png"
                        className="h-4 mb-1 mr-2"
                      />{" "}
                      Pay with Gem
                    </div>
                    <p className="text-sm">
                      Click pay to proceed to Gem payment form
                    </p>
                  </div>
                </Transition>
              </div>
            </div>
          </section>
        )}
        <div className="mt-6 border-t border-gray-200 pt-6">
          {error && (
            <div className="mt-5">
              <ErrorAlert message={error} />
            </div>
          )}
          {checkoutTotals && (
            <>
              <div className="block lg:hidden">
                <div className="flex justify-between">
                  <span>Order summary</span>
                  <button
                    type="button"
                    onClick={onShowDetails}
                    className="flex items-center mr-2 text-md text-gray-400 underline decoration-dotted"
                  >
                    Details
                  </button>
                </div>
                <dl className="mx-auto max-w-lg space-y-4 mt-6 border-b border-gray-200 pb-6">
                  <div className="flex text-sm items-center justify-between">
                    <dt className="text-gray-600">Cart Total</dt>
                    <dd>
                      {formatPrice(
                        checkoutTotals.subtotal,
                        checkout.currencyCode,
                      )}
                    </dd>
                  </div>
                  <div className="flex text-sm items-center justify-between">
                    <dt className="text-gray-600">Savings</dt>
                    <dd>
                      {formatPrice(
                        checkoutTotals.discounts,
                        checkout.currencyCode,
                      )}
                    </dd>
                  </div>
                  <DiscountCodeForm />
                  <GiftCardForm />
                  <div className="flex text-sm items-center justify-between">
                    <dt className="text-gray-600">Shipping</dt>
                    <dd>
                      {formatPrice(
                        checkoutTotals.shipping,
                        checkout.currencyCode,
                      )}
                    </dd>
                  </div>
                  <div className="flex text-md font-medium items-center justify-between">
                    <dt className="text-gray-600">Total</dt>
                    <dd>
                      {formatPrice(checkoutTotals.total, checkout.currencyCode)}
                    </dd>
                  </div>
                  <div className="text-xs text-gray-700 font-light">
                    including{" "}
                    {formatPrice(checkoutTotals.tax, checkout.currencyCode)} in
                    taxes
                  </div>
                  <div className="flex text-md font-medium items-center justify-between">
                    <dt className="text-gray-600">Amount due</dt>
                    <dd>
                      {formatPrice(
                        checkoutTotals.amountDue,
                        checkout.currencyCode,
                      )}
                    </dd>
                  </div>
                </dl>
              </div>
              <LoadingButton loading={loading} type="submit">
                <span>Pay</span>
              </LoadingButton>
            </>
          )}
        </div>
      </section>
    </form>
  );
};

export default CheckoutForm;
