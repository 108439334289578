import React, { useEffect } from 'react';
import { Combobox } from "@headlessui/react";
import { ChangeEvent } from "react";
import usePlacesAutocomplete, {getDetails} from "use-places-autocomplete";
import {IGoogleAddress, IShippingAddress} from "../types/types";
import LoadingSpinner from "./LoadingSpinner";
import config from "../utils/config";

const checkPlacesAvailability = (): Promise<boolean> => {
  return new Promise((resolve) => {
    if (typeof window.google === 'undefined' || typeof window.google.maps === 'undefined') {
      resolve(false);
    } else {
      // Check if the Places library is loaded
      if (typeof window.google.maps.places === 'undefined') {
        resolve(false);
      } else {
        // Attempt to create an AutocompleteService instance
        try {
          new window.google.maps.places.AutocompleteService();
          resolve(true);
        } catch (error) {
          resolve(false);
        }
      }
    }
  });
};

const PlacesAutocomplete = function ({ name, initialValue, onChange }: {name: string, initialValue: string, onChange: (value: Partial<IShippingAddress> | null) => void}) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete({
    callbackName: 'bushbuckPlacesAutocomplete',
    debounce: 300,
    defaultValue: initialValue,
    requestOptions: {
      types: ['address'],
      componentRestrictions: {
        country: ['nz']
      }
    },
  });

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    if (e.target.value === "" || !e.target.value) {
      onChange(null);
    }
    onChange({ address1: e.target.value });
  };

  useEffect(() => {
    // Check availability when the component mounts
    checkPlacesAvailability().then((isAvailable) => {
      if (!isAvailable) {
        console.warn('Google Places API is not available');
        // You might want to inform the parent component here
        // For example: onAvailabilityChange(false);
      }
    });
  }, []);

  function isAutocompletePredicion(data: any): data is google.maps.places.AutocompletePrediction {
    return typeof data === "object" && "description" in data && "place_id" in data;
  }

  function isDetailsObject(data: any): data is { address_components: google.maps.GeocoderAddressComponent[], formatted_address: string } {
    return typeof data === "object" && "address_components" in data && "formatted_address" in data;
  }

  const handleSelect = (val: unknown) => {
    if (isAutocompletePredicion(val)) {
      getDetails({
        placeId: val.place_id,
        fields: ["address_components", "formatted_address"],
      })
        .then((details) => {
          if (isDetailsObject(details)) {
            const address = formatAddressComponents(details.address_components);
            setValue(address?.address1 ?? '', false);
            onChange(address);
            return;
          }
        })
        .catch((error) => {
          onChange(null);
          return;
        });
    }
  };

  function formatAddressForNZ(mappedAddress: IGoogleAddress) : Partial<IShippingAddress> {
    let streetAddress = `${mappedAddress.street_number ?? ''} ${mappedAddress.route ?? ''}`;
    if (mappedAddress.subpremise) {
      streetAddress = `${mappedAddress.subpremise}/${streetAddress}`;
    }
    const formattedAddress : Partial<IShippingAddress> = {
      address1: streetAddress,
      address2: mappedAddress.sublocality,
      city: mappedAddress.locality ?? '',
      zip: mappedAddress.postal_code ?? '',
      country: mappedAddress.country ?? '',
    };
    if (mappedAddress.administrative_area_level_1) {
      const region = mapToRegionCode(mappedAddress.administrative_area_level_1);
      if (region) {
        formattedAddress.province = region;
      }
    }
    return formattedAddress;
  }

  function mapToRegionCode(region: string): string | null {
    const matchedRegion = config.shippingRegions.find((shippingRegion) => {
      return shippingRegion.code === region || shippingRegion.label === region;
    });
    return matchedRegion?.code ?? null;
  }

  function formatAddressForGB(mappedAddress: IGoogleAddress) : Partial<IShippingAddress> {
    return {
      address1: `${mappedAddress.street_number} ${mappedAddress.route}`,
      address2: mappedAddress.locality,
      city: mappedAddress.postal_town ?? '',
      province: mappedAddress.administrative_area_level_2 ?? '',
      zip: mappedAddress.postal_code ?? '',
      country: mappedAddress.country ?? '',
    };
  }

  function formatAddressForAU(mappedAddress: IGoogleAddress) : Partial<IShippingAddress> {
    let streetAddress = `${mappedAddress.street_number} ${mappedAddress.route}`;
    if (mappedAddress.subpremise) {
      streetAddress = `${mappedAddress.subpremise}/${streetAddress}`;
    }
    return {
      address1: streetAddress,
      address2: null,
      city: mappedAddress.locality ?? '',
      province: mappedAddress.administrative_area_level_1 ?? '',
      zip: mappedAddress.postal_code ?? '',
      country: mappedAddress.country ?? '',
    };
  }

  function formatAddressForUS(mappedAddress: IGoogleAddress) : Partial<IShippingAddress> {
    return {
      address1: `${mappedAddress.street_number} ${mappedAddress.route}`,
      address2: null,
      city: mappedAddress.postal_town ?? '',
      province: mappedAddress.locality ?? '',
      zip: mappedAddress.postal_code ?? '',
      country: mappedAddress.country ?? '',
    };
  }

  const formatAddressComponents = (components: google.maps.GeocoderAddressComponent[]): Partial<IShippingAddress> => {
    const mappedAddress = mapComponentsToObject(components);

    if (mappedAddress.country === 'NZ') {
      return formatAddressForNZ(mappedAddress);
    }
    if (mappedAddress.country === 'AU') {
      return formatAddressForAU(mappedAddress);
    }

    if (mappedAddress.country === 'US') {
      return formatAddressForUS(mappedAddress);
    }

    if (mappedAddress.country === 'GB') {
      return formatAddressForGB(mappedAddress);
    }

    throw new Error('Unsupported country');
  }

  const mapComponentsToObject = (components: google.maps.GeocoderAddressComponent[]) : IGoogleAddress =>  {
    let addressObject : IGoogleAddress = {
      subpremise: null,
      street_number: null,
      route: null,
      postal_town: null,
      locality: null,
      sublocality: null,
      administrative_area_level_2: null,
      administrative_area_level_1: null,
      postal_code: null,
      country: null,
    };

    // Map google's response into a more usable format
    for (let component of components) {
      const types = component.types;

      if (types.includes('street_number')) {
        addressObject.street_number = component.long_name;
      } else if (types.includes('subpremise')) {
        addressObject.subpremise = component.long_name;
      } else if (types.includes('route')) {
        addressObject.route = component.long_name;
      } else if (types.includes('postal_town')) {
        addressObject.postal_town = component.long_name;
      } else if (types.includes('locality')) {
        addressObject.locality = component.long_name;
      } else if (types.includes('sublocality')) {
        addressObject.sublocality = component.long_name;
      } else if (types.includes('administrative_area_level_2')) {
        addressObject.administrative_area_level_2 = component.long_name;
      } else if (types.includes('administrative_area_level_1')) {
        addressObject.administrative_area_level_1 = component.long_name;
      } else if (types.includes('postal_code')) {
        addressObject.postal_code = component.long_name;
      } else if (types.includes('country')) {
        addressObject.country = component.short_name;
      }
    }

    return addressObject;
  }

  return (
    <Combobox as="div" name={name} value={value} onChange={handleSelect} disabled={!ready}>
      <div
        className="relative mt-2">
        <Combobox.Input
          className="relative block w-full rounded-full p-3 border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-4 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
          onChange={handleInput}
          value={value}
          placeholder="Start typing address"
          displayValue={(value: google.maps.places.AutocompletePrediction) => value.description}
          autoComplete="street-address"
        />
        <Combobox.Options
          className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-2xl bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {value && status !== "OK" && (
            <Combobox.Option
              className={`relative cursor-default select-none py-2 pl-4 pr-4 text-gray-900`}
              key="loading"
              value="0"
              disabled={true}
            >
              <LoadingSpinner />
            </Combobox.Option>
          )}
          {status === "OK" &&
            data.map((prediction) => (
              <Combobox.Option
                className={({active}) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? "bg-gray-100 text-black" : "text-gray-900"
                  }`
                }
                key={prediction.place_id}
                value={prediction}
              >
                {prediction.description}
              </Combobox.Option>
            ))}
        </Combobox.Options>
      </div>
    </Combobox>
  );
}

PlacesAutocomplete.checkAvailability = checkPlacesAvailability;
export default PlacesAutocomplete;
