export type Region = {
  postcode: string;
  region: string;
  code: string;
};

export const regionMap: Region[] = [
  { postcode: '7843', region: 'West Coast', code: 'WTC' },
  { postcode: '0449', region: 'Northland', code: 'NTL' },
  { postcode: '7520', region: 'Canterbury', code: 'CAN' },
  { postcode: '7542', region: 'Canterbury', code: 'CAN' },
  { postcode: '7550', region: 'Canterbury', code: 'CAN' },
  { postcode: '7552', region: 'Canterbury', code: 'CAN' },
  { postcode: '7581', region: 'Canterbury', code: 'CAN' },
  { postcode: '7582', region: 'Canterbury', code: 'CAN' },
  { postcode: '7583', region: 'Canterbury', code: 'CAN' },
  { postcode: '0792', region: 'Auckland', code: 'AUK' },
  { postcode: '0793', region: 'Auckland', code: 'AUK' },
  { postcode: '0794', region: 'Auckland', code: 'AUK' },
  { postcode: '9320', region: 'Otago', code: 'OTA' },
  { postcode: '9340', region: 'Otago', code: 'OTA' },
  { postcode: '9391', region: 'Otago', code: 'OTA' },
  { postcode: '9392', region: 'Otago', code: 'OTA' },
  { postcode: '9393', region: 'Otago', code: 'OTA' },
  { postcode: '7410', region: 'Canterbury', code: 'CAN' },
  { postcode: '7441', region: 'Canterbury', code: 'CAN' },
  { postcode: '7481', region: 'Canterbury', code: 'CAN' },
  { postcode: '7481', region: 'Canterbury', code: 'CAN' },
  { postcode: '7482', region: 'Canterbury', code: 'CAN' },
  { postcode: '7483', region: 'Canterbury', code: 'CAN' },
  { postcode: '7946', region: 'Canterbury', code: 'CAN' },
  { postcode: '4771', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '3979', region: 'Waikato', code: 'WKO' },
  { postcode: '3979', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '9302', region: 'Otago', code: 'OTA' },
  { postcode: '9351', region: 'Otago', code: 'OTA' },
  { postcode: '7654', region: 'Canterbury', code: 'CAN' },
  { postcode: '7700', region: 'Canterbury', code: 'CAN' },
  { postcode: '7740', region: 'Canterbury', code: 'CAN' },
  { postcode: '7741', region: 'Canterbury', code: 'CAN' },
  { postcode: '7742', region: 'Canterbury', code: 'CAN' },
  { postcode: '7746', region: 'Canterbury', code: 'CAN' },
  { postcode: '7771', region: 'Canterbury', code: 'CAN' },
  { postcode: '7772', region: 'Canterbury', code: 'CAN' },
  { postcode: '7773', region: 'Canterbury', code: 'CAN' },
  { postcode: '7774', region: 'Canterbury', code: 'CAN' },
  { postcode: '7775', region: 'Canterbury', code: 'CAN' },
  { postcode: '7776', region: 'Canterbury', code: 'CAN' },
  { postcode: '7777', region: 'Canterbury', code: 'CAN' },
  { postcode: '7778', region: 'Canterbury', code: 'CAN' },
  { postcode: '4810', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4847', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4884', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '9749', region: 'Southland', code: 'STL' },
  { postcode: '3078', region: 'Waikato', code: 'WKO' },
  { postcode: '3078', region: 'Waikato', code: 'WKO' },
  { postcode: '3078', region: 'Waikato', code: 'WKO' },
  { postcode: '0600', region: 'Auckland', code: 'AUK' },
  { postcode: '0602', region: 'Auckland', code: 'AUK' },
  { postcode: '0604', region: 'Auckland', code: 'AUK' },
  { postcode: '0610', region: 'Auckland', code: 'AUK' },
  { postcode: '0612', region: 'Auckland', code: 'AUK' },
  { postcode: '0614', region: 'Auckland', code: 'AUK' },
  { postcode: '0616', region: 'Auckland', code: 'AUK' },
  { postcode: '0618', region: 'Auckland', code: 'AUK' },
  { postcode: '0620', region: 'Auckland', code: 'AUK' },
  { postcode: '0622', region: 'Auckland', code: 'AUK' },
  { postcode: '0624', region: 'Auckland', code: 'AUK' },
  { postcode: '0626', region: 'Auckland', code: 'AUK' },
  { postcode: '0627', region: 'Auckland', code: 'AUK' },
  { postcode: '0629', region: 'Auckland', code: 'AUK' },
  { postcode: '0630', region: 'Auckland', code: 'AUK' },
  { postcode: '0632', region: 'Auckland', code: 'AUK' },
  { postcode: '0640', region: 'Auckland', code: 'AUK' },
  { postcode: '0641', region: 'Auckland', code: 'AUK' },
  { postcode: '0642', region: 'Auckland', code: 'AUK' },
  { postcode: '0643', region: 'Auckland', code: 'AUK' },
  { postcode: '0644', region: 'Auckland', code: 'AUK' },
  { postcode: '0645', region: 'Auckland', code: 'AUK' },
  { postcode: '0650', region: 'Auckland', code: 'AUK' },
  { postcode: '0651', region: 'Auckland', code: 'AUK' },
  { postcode: '0652', region: 'Auckland', code: 'AUK' },
  { postcode: '0653', region: 'Auckland', code: 'AUK' },
  { postcode: '0654', region: 'Auckland', code: 'AUK' },
  { postcode: '0655', region: 'Auckland', code: 'AUK' },
  { postcode: '0656', region: 'Auckland', code: 'AUK' },
  { postcode: '0657', region: 'Auckland', code: 'AUK' },
  { postcode: '0660', region: 'Auckland', code: 'AUK' },
  { postcode: '0661', region: 'Auckland', code: 'AUK' },
  { postcode: '0662', region: 'Auckland', code: 'AUK' },
  { postcode: '0664', region: 'Auckland', code: 'AUK' },
  { postcode: '0740', region: 'Auckland', code: 'AUK' },
  { postcode: '0741', region: 'Auckland', code: 'AUK' },
  { postcode: '0742', region: 'Auckland', code: 'AUK' },
  { postcode: '0743', region: 'Auckland', code: 'AUK' },
  { postcode: '0744', region: 'Auckland', code: 'AUK' },
  { postcode: '0745', region: 'Auckland', code: 'AUK' },
  { postcode: '0746', region: 'Auckland', code: 'AUK' },
  { postcode: '0747', region: 'Auckland', code: 'AUK' },
  { postcode: '0748', region: 'Auckland', code: 'AUK' },
  { postcode: '0749', region: 'Auckland', code: 'AUK' },
  { postcode: '0750', region: 'Auckland', code: 'AUK' },
  { postcode: '0751', region: 'Auckland', code: 'AUK' },
  { postcode: '0752', region: 'Auckland', code: 'AUK' },
  { postcode: '0753', region: 'Auckland', code: 'AUK' },
  { postcode: '0754', region: 'Auckland', code: 'AUK' },
  { postcode: '0755', region: 'Auckland', code: 'AUK' },
  { postcode: '0756', region: 'Auckland', code: 'AUK' },
  { postcode: '0757', region: 'Auckland', code: 'AUK' },
  { postcode: '0758', region: 'Auckland', code: 'AUK' },
  { postcode: '0760', region: 'Auckland', code: 'AUK' },
  { postcode: '0814', region: 'Auckland', code: 'AUK' },
  { postcode: '0816', region: 'Auckland', code: 'AUK' },
  { postcode: '1010', region: 'Auckland', code: 'AUK' },
  { postcode: '1011', region: 'Auckland', code: 'AUK' },
  { postcode: '1021', region: 'Auckland', code: 'AUK' },
  { postcode: '1022', region: 'Auckland', code: 'AUK' },
  { postcode: '1023', region: 'Auckland', code: 'AUK' },
  { postcode: '1024', region: 'Auckland', code: 'AUK' },
  { postcode: '1025', region: 'Auckland', code: 'AUK' },
  { postcode: '1026', region: 'Auckland', code: 'AUK' },
  { postcode: '1041', region: 'Auckland', code: 'AUK' },
  { postcode: '1042', region: 'Auckland', code: 'AUK' },
  { postcode: '1050', region: 'Auckland', code: 'AUK' },
  { postcode: '1051', region: 'Auckland', code: 'AUK' },
  { postcode: '1052', region: 'Auckland', code: 'AUK' },
  { postcode: '1060', region: 'Auckland', code: 'AUK' },
  { postcode: '1061', region: 'Auckland', code: 'AUK' },
  { postcode: '1062', region: 'Auckland', code: 'AUK' },
  { postcode: '1071', region: 'Auckland', code: 'AUK' },
  { postcode: '1072', region: 'Auckland', code: 'AUK' },
  { postcode: '1140', region: 'Auckland', code: 'AUK' },
  { postcode: '1142', region: 'Auckland', code: 'AUK' },
  { postcode: '1143', region: 'Auckland', code: 'AUK' },
  { postcode: '1144', region: 'Auckland', code: 'AUK' },
  { postcode: '1146', region: 'Auckland', code: 'AUK' },
  { postcode: '1147', region: 'Auckland', code: 'AUK' },
  { postcode: '1148', region: 'Auckland', code: 'AUK' },
  { postcode: '1149', region: 'Auckland', code: 'AUK' },
  { postcode: '1150', region: 'Auckland', code: 'AUK' },
  { postcode: '1151', region: 'Auckland', code: 'AUK' },
  { postcode: '1245', region: 'Auckland', code: 'AUK' },
  { postcode: '1246', region: 'Auckland', code: 'AUK' },
  { postcode: '1342', region: 'Auckland', code: 'AUK' },
  { postcode: '1344', region: 'Auckland', code: 'AUK' },
  { postcode: '1345', region: 'Auckland', code: 'AUK' },
  { postcode: '1348', region: 'Auckland', code: 'AUK' },
  { postcode: '1349', region: 'Auckland', code: 'AUK' },
  { postcode: '1350', region: 'Auckland', code: 'AUK' },
  { postcode: '1351', region: 'Auckland', code: 'AUK' },
  { postcode: '1352', region: 'Auckland', code: 'AUK' },
  { postcode: '1440', region: 'Auckland', code: 'AUK' },
  { postcode: '1443', region: 'Auckland', code: 'AUK' },
  { postcode: '1445', region: 'Auckland', code: 'AUK' },
  { postcode: '1446', region: 'Auckland', code: 'AUK' },
  { postcode: '1541', region: 'Auckland', code: 'AUK' },
  { postcode: '1542', region: 'Auckland', code: 'AUK' },
  { postcode: '1545', region: 'Auckland', code: 'AUK' },
  { postcode: '1546', region: 'Auckland', code: 'AUK' },
  { postcode: '1640', region: 'Auckland', code: 'AUK' },
  { postcode: '1642', region: 'Auckland', code: 'AUK' },
  { postcode: '1643', region: 'Auckland', code: 'AUK' },
  { postcode: '1644', region: 'Auckland', code: 'AUK' },
  { postcode: '1740', region: 'Auckland', code: 'AUK' },
  { postcode: '1741', region: 'Auckland', code: 'AUK' },
  { postcode: '1742', region: 'Auckland', code: 'AUK' },
  { postcode: '1743', region: 'Auckland', code: 'AUK' },
  { postcode: '1745', region: 'Auckland', code: 'AUK' },
  { postcode: '1746', region: 'Auckland', code: 'AUK' },
  { postcode: '2010', region: 'Auckland', code: 'AUK' },
  { postcode: '2012', region: 'Auckland', code: 'AUK' },
  { postcode: '2013', region: 'Auckland', code: 'AUK' },
  { postcode: '2014', region: 'Auckland', code: 'AUK' },
  { postcode: '2016', region: 'Auckland', code: 'AUK' },
  { postcode: '2018', region: 'Auckland', code: 'AUK' },
  { postcode: '2019', region: 'Auckland', code: 'AUK' },
  { postcode: '2022', region: 'Auckland', code: 'AUK' },
  { postcode: '2023', region: 'Auckland', code: 'AUK' },
  { postcode: '2024', region: 'Auckland', code: 'AUK' },
  { postcode: '2025', region: 'Auckland', code: 'AUK' },
  { postcode: '2102', region: 'Auckland', code: 'AUK' },
  { postcode: '2103', region: 'Auckland', code: 'AUK' },
  { postcode: '2104', region: 'Auckland', code: 'AUK' },
  { postcode: '2105', region: 'Auckland', code: 'AUK' },
  { postcode: '2140', region: 'Auckland', code: 'AUK' },
  { postcode: '2143', region: 'Auckland', code: 'AUK' },
  { postcode: '2144', region: 'Auckland', code: 'AUK' },
  { postcode: '2145', region: 'Auckland', code: 'AUK' },
  { postcode: '2146', region: 'Auckland', code: 'AUK' },
  { postcode: '2147', region: 'Auckland', code: 'AUK' },
  { postcode: '2148', region: 'Auckland', code: 'AUK' },
  { postcode: '2150', region: 'Auckland', code: 'AUK' },
  { postcode: '2151', region: 'Auckland', code: 'AUK' },
  { postcode: '2153', region: 'Auckland', code: 'AUK' },
  { postcode: '2154', region: 'Auckland', code: 'AUK' },
  { postcode: '2155', region: 'Auckland', code: 'AUK' },
  { postcode: '2156', region: 'Auckland', code: 'AUK' },
  { postcode: '2158', region: 'Auckland', code: 'AUK' },
  { postcode: '2159', region: 'Auckland', code: 'AUK' },
  { postcode: '2160', region: 'Auckland', code: 'AUK' },
  { postcode: '2161', region: 'Auckland', code: 'AUK' },
  { postcode: '2163', region: 'Auckland', code: 'AUK' },
  { postcode: '2164', region: 'Auckland', code: 'AUK' },
  { postcode: '2241', region: 'Auckland', code: 'AUK' },
  { postcode: '2242', region: 'Auckland', code: 'AUK' },
  { postcode: '2243', region: 'Auckland', code: 'AUK' },
  { postcode: '0451', region: 'Northland', code: 'NTL' },
  { postcode: '0486', region: 'Northland', code: 'NTL' },
  { postcode: '9230', region: 'Otago', code: 'OTA' },
  { postcode: '9240', region: 'Otago', code: 'OTA' },
  { postcode: '9271', region: 'Otago', code: 'OTA' },
  { postcode: '9272', region: 'Otago', code: 'OTA' },
  { postcode: '9273', region: 'Otago', code: 'OTA' },
  { postcode: '9274', region: 'Otago', code: 'OTA' },
  { postcode: '9746', region: 'Southland', code: 'STL' },
  { postcode: '9779', region: 'Southland', code: 'STL' },
  { postcode: '7670', region: 'Canterbury', code: 'CAN' },
  { postcode: '3944', region: 'Waikato', code: 'WKO' },
  { postcode: '7804', region: 'West Coast', code: 'WTC' },
  { postcode: '7871', region: 'West Coast', code: 'WTC' },
  { postcode: '7871', region: 'West Coast', code: 'WTC' },
  { postcode: '7201', region: 'Marlborough', code: 'MBH' },
  { postcode: '7202', region: 'Marlborough', code: 'MBH' },
  { postcode: '7206', region: 'Marlborough', code: 'MBH' },
  { postcode: '7240', region: 'Marlborough', code: 'MBH' },
  { postcode: '7241', region: 'Marlborough', code: 'MBH' },
  { postcode: '7271', region: 'Marlborough', code: 'MBH' },
  { postcode: '7272', region: 'Marlborough', code: 'MBH' },
  { postcode: '7273', region: 'Marlborough', code: 'MBH' },
  { postcode: '7274', region: 'Marlborough', code: 'MBH' },
  { postcode: '7275', region: 'Marlborough', code: 'MBH' },
  { postcode: '7276', region: 'Marlborough', code: 'MBH' },
  { postcode: '9814', region: 'Southland', code: 'STL' },
  { postcode: '9842', region: 'Southland', code: 'STL' },
  { postcode: '2343', region: 'Auckland', code: 'AUK' },
  { postcode: '2675', region: 'Waikato', code: 'WKO' },
  { postcode: '2675', region: 'Auckland', code: 'AUK' },
  { postcode: '9091', region: 'Otago', code: 'OTA' },
  { postcode: '9091', region: 'Otago', code: 'OTA' },
  { postcode: '7022', region: 'Tasman', code: 'TAS' },
  { postcode: '7051', region: 'Tasman', code: 'TAS' },
  { postcode: '7091', region: 'Tasman', code: 'TAS' },
  { postcode: '0462', region: 'Northland', code: 'NTL' },
  { postcode: '0496', region: 'Northland', code: 'NTL' },
  { postcode: '7836', region: 'West Coast', code: 'WTC' },
  { postcode: '4816', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4818', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4863', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4894', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4867', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '7600', region: 'Canterbury', code: 'CAN' },
  { postcode: '0420', region: 'Northland', code: 'NTL' },
  { postcode: '3432', region: 'Waikato', code: 'WKO' },
  { postcode: '3434', region: 'Waikato', code: 'WKO' },
  { postcode: '3450', region: 'Waikato', code: 'WKO' },
  { postcode: '3493', region: 'Waikato', code: 'WKO' },
  { postcode: '3493', region: 'Waikato', code: 'WKO' },
  { postcode: '3494', region: 'Waikato', code: 'WKO' },
  { postcode: '3494', region: 'Waikato', code: 'WKO' },
  { postcode: '3495', region: 'Waikato', code: 'WKO' },
  { postcode: '3496', region: 'Waikato', code: 'WKO' },
  { postcode: '3496', region: 'Waikato', code: 'WKO' },
  { postcode: '5713', region: 'Wellington', code: 'WGN' },
  { postcode: '5743', region: 'Wellington', code: 'WGN' },
  { postcode: '5791', region: 'Wellington', code: 'WGN' },
  { postcode: '5792', region: 'Wellington', code: 'WGN' },
  { postcode: '5792', region: 'Wellington', code: 'WGN' },
  { postcode: '7957', region: 'Canterbury', code: 'CAN' },
  { postcode: '7984', region: 'Canterbury', code: 'CAN' },
  { postcode: '7984', region: 'Canterbury', code: 'CAN' },
  { postcode: '7984', region: 'Canterbury', code: 'CAN' },
  { postcode: '7865', region: 'West Coast', code: 'WTC' },
  { postcode: '7310', region: 'Canterbury', code: 'CAN' },
  { postcode: '7341', region: 'Canterbury', code: 'CAN' },
  { postcode: '7381', region: 'Canterbury', code: 'CAN' },
  { postcode: '7382', region: 'Canterbury', code: 'CAN' },
  { postcode: '7383', region: 'Canterbury', code: 'CAN' },
  { postcode: '7384', region: 'Canterbury', code: 'CAN' },
  { postcode: '7671', region: 'Canterbury', code: 'CAN' },
  { postcode: '7671', region: 'Canterbury', code: 'CAN' },
  { postcode: '7672', region: 'Canterbury', code: 'CAN' },
  { postcode: '7672', region: 'Canterbury', code: 'CAN' },
  { postcode: '7674', region: 'Canterbury', code: 'CAN' },
  { postcode: '7674', region: 'Canterbury', code: 'CAN' },
  { postcode: '7675', region: 'Canterbury', code: 'CAN' },
  { postcode: '7676', region: 'Canterbury', code: 'CAN' },
  { postcode: '7676', region: 'Canterbury', code: 'CAN' },
  { postcode: '7677', region: 'Canterbury', code: 'CAN' },
  { postcode: '7678', region: 'Canterbury', code: 'CAN' },
  { postcode: '7678', region: 'Canterbury', code: 'CAN' },
  { postcode: '8011', region: 'Canterbury', code: 'CAN' },
  { postcode: '8013', region: 'Canterbury', code: 'CAN' },
  { postcode: '8014', region: 'Canterbury', code: 'CAN' },
  { postcode: '8022', region: 'Canterbury', code: 'CAN' },
  { postcode: '8023', region: 'Canterbury', code: 'CAN' },
  { postcode: '8024', region: 'Canterbury', code: 'CAN' },
  { postcode: '8025', region: 'Canterbury', code: 'CAN' },
  { postcode: '8025', region: 'Canterbury', code: 'CAN' },
  { postcode: '8041', region: 'Canterbury', code: 'CAN' },
  { postcode: '8042', region: 'Canterbury', code: 'CAN' },
  { postcode: '8042', region: 'Canterbury', code: 'CAN' },
  { postcode: '8051', region: 'Canterbury', code: 'CAN' },
  { postcode: '8052', region: 'Canterbury', code: 'CAN' },
  { postcode: '8053', region: 'Canterbury', code: 'CAN' },
  { postcode: '8061', region: 'Canterbury', code: 'CAN' },
  { postcode: '8062', region: 'Canterbury', code: 'CAN' },
  { postcode: '8081', region: 'Canterbury', code: 'CAN' },
  { postcode: '8083', region: 'Canterbury', code: 'CAN' },
  { postcode: '8140', region: 'Canterbury', code: 'CAN' },
  { postcode: '8141', region: 'Canterbury', code: 'CAN' },
  { postcode: '8143', region: 'Canterbury', code: 'CAN' },
  { postcode: '8145', region: 'Canterbury', code: 'CAN' },
  { postcode: '8146', region: 'Canterbury', code: 'CAN' },
  { postcode: '8147', region: 'Canterbury', code: 'CAN' },
  { postcode: '8148', region: 'Canterbury', code: 'CAN' },
  { postcode: '8149', region: 'Canterbury', code: 'CAN' },
  { postcode: '8154', region: 'Canterbury', code: 'CAN' },
  { postcode: '8240', region: 'Canterbury', code: 'CAN' },
  { postcode: '8241', region: 'Canterbury', code: 'CAN' },
  { postcode: '8242', region: 'Canterbury', code: 'CAN' },
  { postcode: '8244', region: 'Canterbury', code: 'CAN' },
  { postcode: '8245', region: 'Canterbury', code: 'CAN' },
  { postcode: '8247', region: 'Canterbury', code: 'CAN' },
  { postcode: '8440', region: 'Canterbury', code: 'CAN' },
  { postcode: '8441', region: 'Canterbury', code: 'CAN' },
  { postcode: '8442', region: 'Canterbury', code: 'CAN' },
  { postcode: '8443', region: 'Canterbury', code: 'CAN' },
  { postcode: '8444', region: 'Canterbury', code: 'CAN' },
  { postcode: '8446', region: 'Canterbury', code: 'CAN' },
  { postcode: '8542', region: 'Canterbury', code: 'CAN' },
  { postcode: '8543', region: 'Canterbury', code: 'CAN' },
  { postcode: '8544', region: 'Canterbury', code: 'CAN' },
  { postcode: '8545', region: 'Canterbury', code: 'CAN' },
  { postcode: '8548', region: 'Canterbury', code: 'CAN' },
  { postcode: '8640', region: 'Canterbury', code: 'CAN' },
  { postcode: '8641', region: 'Canterbury', code: 'CAN' },
  { postcode: '8643', region: 'Canterbury', code: 'CAN' },
  { postcode: '8840', region: 'Canterbury', code: 'CAN' },
  { postcode: '8842', region: 'Canterbury', code: 'CAN' },
  { postcode: '2122', region: 'Auckland', code: 'AUK' },
  { postcode: '2248', region: 'Auckland', code: 'AUK' },
  { postcode: '9534', region: 'Otago', code: 'OTA' },
  { postcode: '9548', region: 'Otago', code: 'OTA' },
  { postcode: '9583', region: 'Otago', code: 'OTA' },
  { postcode: '9584', region: 'Otago', code: 'OTA' },
  { postcode: '4102', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4148', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '9330', region: 'Otago', code: 'OTA' },
  { postcode: '9341', region: 'Otago', code: 'OTA' },
  { postcode: '7646', region: 'Canterbury', code: 'CAN' },
  { postcode: '7673', region: 'Canterbury', code: 'CAN' },
  { postcode: '7054', region: 'Tasman', code: 'TAS' },
  { postcode: '7073', region: 'Tasman', code: 'TAS' },
  { postcode: '3547', region: 'Waikato', code: 'WKO' },
  { postcode: '0420', region: 'Northland', code: 'NTL' },
  { postcode: '3506', region: 'Waikato', code: 'WKO' },
  { postcode: '3543', region: 'Waikato', code: 'WKO' },
  { postcode: '3581', region: 'Waikato', code: 'WKO' },
  { postcode: '3582', region: 'Waikato', code: 'WKO' },
  { postcode: '3583', region: 'Waikato', code: 'WKO' },
  { postcode: '3584', region: 'Waikato', code: 'WKO' },
  { postcode: '3584', region: 'Waikato', code: 'WKO' },
  { postcode: '9310', region: 'Otago', code: 'OTA' },
  { postcode: '9342', region: 'Otago', code: 'OTA' },
  { postcode: '9383', region: 'Otago', code: 'OTA' },
  { postcode: '9383', region: 'Otago', code: 'OTA' },
  { postcode: '9384', region: 'Otago', code: 'OTA' },
  { postcode: '7345', region: 'Canterbury', code: 'CAN' },
  { postcode: '7391', region: 'Canterbury', code: 'CAN' },
  { postcode: '7392', region: 'Canterbury', code: 'CAN' },
  { postcode: '7444', region: 'Canterbury', code: 'CAN' },
  { postcode: '0632', region: 'Auckland', code: 'AUK' },
  { postcode: '4930', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4942', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4970', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4970', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4971', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4972', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4973', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4975', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4976', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4977', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4978', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4979', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '7510', region: 'Canterbury', code: 'CAN' },
  { postcode: '7541', region: 'Canterbury', code: 'CAN' },
  { postcode: '7571', region: 'Canterbury', code: 'CAN' },
  { postcode: '7572', region: 'Canterbury', code: 'CAN' },
  { postcode: '0310', region: 'Northland', code: 'NTL' },
  { postcode: '0340', region: 'Northland', code: 'NTL' },
  { postcode: '0370', region: 'Northland', code: 'NTL' },
  { postcode: '0371', region: 'Northland', code: 'NTL' },
  { postcode: '0372', region: 'Northland', code: 'NTL' },
  { postcode: '0372', region: 'Northland', code: 'NTL' },
  { postcode: '0373', region: 'Northland', code: 'NTL' },
  { postcode: '0374', region: 'Northland', code: 'NTL' },
  { postcode: '0376', region: 'Northland', code: 'NTL' },
  { postcode: '0377', region: 'Northland', code: 'NTL' },
  { postcode: '0379', region: 'Northland', code: 'NTL' },
  { postcode: '8941', region: 'Canterbury', code: 'CAN' },
  { postcode: '8972', region: 'Canterbury', code: 'CAN' },
  { postcode: '9752', region: 'Southland', code: 'STL' },
  { postcode: '9791', region: 'Southland', code: 'STL' },
  { postcode: '7872', region: 'West Coast', code: 'WTC' },
  { postcode: '7548', region: 'Canterbury', code: 'CAN' },
  { postcode: '2113', region: 'Auckland', code: 'AUK' },
  { postcode: '2247', region: 'Auckland', code: 'AUK' },
  { postcode: '2577', region: 'Auckland', code: 'AUK' },
  { postcode: '2578', region: 'Auckland', code: 'AUK' },
  { postcode: '2579', region: 'Auckland', code: 'AUK' },
  { postcode: '9010', region: 'Otago', code: 'OTA' },
  { postcode: '9011', region: 'Otago', code: 'OTA' },
  { postcode: '9012', region: 'Otago', code: 'OTA' },
  { postcode: '9013', region: 'Otago', code: 'OTA' },
  { postcode: '9014', region: 'Otago', code: 'OTA' },
  { postcode: '9016', region: 'Otago', code: 'OTA' },
  { postcode: '9018', region: 'Otago', code: 'OTA' },
  { postcode: '9022', region: 'Otago', code: 'OTA' },
  { postcode: '9035', region: 'Otago', code: 'OTA' },
  { postcode: '9040', region: 'Otago', code: 'OTA' },
  { postcode: '9041', region: 'Otago', code: 'OTA' },
  { postcode: '9042', region: 'Otago', code: 'OTA' },
  { postcode: '9043', region: 'Otago', code: 'OTA' },
  { postcode: '9044', region: 'Otago', code: 'OTA' },
  { postcode: '9045', region: 'Otago', code: 'OTA' },
  { postcode: '9046', region: 'Otago', code: 'OTA' },
  { postcode: '9047', region: 'Otago', code: 'OTA' },
  { postcode: '9048', region: 'Otago', code: 'OTA' },
  { postcode: '9049', region: 'Otago', code: 'OTA' },
  { postcode: '9052', region: 'Otago', code: 'OTA' },
  { postcode: '9054', region: 'Otago', code: 'OTA' },
  { postcode: '9059', region: 'Otago', code: 'OTA' },
  { postcode: '9076', region: 'Otago', code: 'OTA' },
  { postcode: '9077', region: 'Otago', code: 'OTA' },
  { postcode: '7657', region: 'Canterbury', code: 'CAN' },
  { postcode: '7545', region: 'Canterbury', code: 'CAN' },
  { postcode: '9825', region: 'Southland', code: 'STL' },
  { postcode: '9848', region: 'Southland', code: 'STL' },
  { postcode: '3120', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3160', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '4900', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4940', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4993', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4994', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4994', region: 'Wellington', code: 'WGN' },
  { postcode: '4995', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4996', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4996', region: 'Wellington', code: 'WGN' },
  { postcode: '4996', region: 'Wellington', code: 'WGN' },
  { postcode: '4322', region: 'Taranaki', code: 'TKI' },
  { postcode: '4353', region: 'Taranaki', code: 'TKI' },
  { postcode: '4398', region: 'Taranaki', code: 'TKI' },
  { postcode: '4399', region: 'Taranaki', code: 'TKI' },
  { postcode: '7925', region: 'Canterbury', code: 'CAN' },
  { postcode: '7949', region: 'Canterbury', code: 'CAN' },
  { postcode: '7987', region: 'Canterbury', code: 'CAN' },
  { postcode: '7987', region: 'Canterbury', code: 'CAN' },
  { postcode: '5710', region: 'Wellington', code: 'WGN' },
  { postcode: '5740', region: 'Wellington', code: 'WGN' },
  { postcode: '5771', region: 'Wellington', code: 'WGN' },
  { postcode: '5772', region: 'Wellington', code: 'WGN' },
  { postcode: '5773', region: 'Wellington', code: 'WGN' },
  { postcode: '4702', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4740', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4743', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4775', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4775', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4777', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4779', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '7859', region: 'West Coast', code: 'WTC' },
  { postcode: '4814', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4815', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4848', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4849', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4891', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4891', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4893', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '7856', region: 'West Coast', code: 'WTC' },
  { postcode: '7193', region: 'Marlborough', code: 'MBH' },
  { postcode: '9750', region: 'Southland', code: 'STL' },
  { postcode: '7930', region: 'Canterbury', code: 'CAN' },
  { postcode: '7956', region: 'Canterbury', code: 'CAN' },
  { postcode: '7991', region: 'Canterbury', code: 'CAN' },
  { postcode: '7992', region: 'Canterbury', code: 'CAN' },
  { postcode: '4010', region: 'Gisborne', code: 'GIS' },
  { postcode: '4040', region: 'Gisborne', code: 'GIS' },
  { postcode: '4041', region: 'Gisborne', code: 'GIS' },
  { postcode: '4060', region: 'Gisborne', code: 'GIS' },
  { postcode: '4071', region: 'Gisborne', code: 'GIS' },
  { postcode: '4072', region: 'Gisborne', code: 'GIS' },
  { postcode: '4073', region: 'Gisborne', code: 'GIS' },
  { postcode: '9350', region: 'Otago', code: 'OTA' },
  { postcode: '9372', region: 'Otago', code: 'OTA' },
  { postcode: '3264', region: 'Waikato', code: 'WKO' },
  { postcode: '9710', region: 'Southland', code: 'STL' },
  { postcode: '9740', region: 'Southland', code: 'STL' },
  { postcode: '9771', region: 'Otago', code: 'OTA' },
  { postcode: '9771', region: 'Southland', code: 'STL' },
  { postcode: '9772', region: 'Otago', code: 'OTA' },
  { postcode: '9772', region: 'Southland', code: 'STL' },
  { postcode: '9773', region: 'Southland', code: 'STL' },
  { postcode: '9773', region: 'Southland', code: 'STL' },
  { postcode: '9774', region: 'Southland', code: 'STL' },
  { postcode: '9774', region: 'Southland', code: 'STL' },
  { postcode: '9775', region: 'Otago', code: 'OTA' },
  { postcode: '9775', region: 'Southland', code: 'STL' },
  { postcode: '9776', region: 'Southland', code: 'STL' },
  { postcode: '9776', region: 'Southland', code: 'STL' },
  { postcode: '9777', region: 'Southland', code: 'STL' },
  { postcode: '9777', region: 'Southland', code: 'STL' },
  { postcode: '7823', region: 'West Coast', code: 'WTC' },
  { postcode: '0960', region: 'Auckland', code: 'AUK' },
  { postcode: '0961', region: 'Auckland', code: 'AUK' },
  { postcode: '0962', region: 'Auckland', code: 'AUK' },
  { postcode: '0963', region: 'Auckland', code: 'AUK' },
  { postcode: '0991', region: 'Auckland', code: 'AUK' },
  { postcode: '7387', region: 'Canterbury', code: 'CAN' },
  { postcode: '7802', region: 'West Coast', code: 'WTC' },
  { postcode: '7803', region: 'West Coast', code: 'WTC' },
  { postcode: '7805', region: 'West Coast', code: 'WTC' },
  { postcode: '7832', region: 'West Coast', code: 'WTC' },
  { postcode: '7840', region: 'West Coast', code: 'WTC' },
  { postcode: '7849', region: 'West Coast', code: 'WTC' },
  { postcode: '5712', region: 'Wellington', code: 'WGN' },
  { postcode: '5742', region: 'Wellington', code: 'WGN' },
  { postcode: '5794', region: 'Wellington', code: 'WGN' },
  { postcode: '7844', region: 'West Coast', code: 'WTC' },
  { postcode: '3200', region: 'Waikato', code: 'WKO' },
  { postcode: '3204', region: 'Waikato', code: 'WKO' },
  { postcode: '3204', region: 'Waikato', code: 'WKO' },
  { postcode: '3206', region: 'Waikato', code: 'WKO' },
  { postcode: '3206', region: 'Waikato', code: 'WKO' },
  { postcode: '3210', region: 'Waikato', code: 'WKO' },
  { postcode: '3210', region: 'Waikato', code: 'WKO' },
  { postcode: '3214', region: 'Waikato', code: 'WKO' },
  { postcode: '3216', region: 'Waikato', code: 'WKO' },
  { postcode: '3218', region: 'Waikato', code: 'WKO' },
  { postcode: '3240', region: 'Waikato', code: 'WKO' },
  { postcode: '3241', region: 'Waikato', code: 'WKO' },
  { postcode: '3242', region: 'Waikato', code: 'WKO' },
  { postcode: '3243', region: 'Waikato', code: 'WKO' },
  { postcode: '3244', region: 'Waikato', code: 'WKO' },
  { postcode: '3245', region: 'Waikato', code: 'WKO' },
  { postcode: '3247', region: 'Waikato', code: 'WKO' },
  { postcode: '3248', region: 'Waikato', code: 'WKO' },
  { postcode: '3251', region: 'Waikato', code: 'WKO' },
  { postcode: '3252', region: 'Waikato', code: 'WKO' },
  { postcode: '3253', region: 'Waikato', code: 'WKO' },
  { postcode: '3254', region: 'Waikato', code: 'WKO' },
  { postcode: '3255', region: 'Waikato', code: 'WKO' },
  { postcode: '3256', region: 'Waikato', code: 'WKO' },
  { postcode: '3257', region: 'Waikato', code: 'WKO' },
  { postcode: '3263', region: 'Waikato', code: 'WKO' },
  { postcode: '3281', region: 'Waikato', code: 'WKO' },
  { postcode: '3281', region: 'Waikato', code: 'WKO' },
  { postcode: '3282', region: 'Waikato', code: 'WKO' },
  { postcode: '3282', region: 'Waikato', code: 'WKO' },
  { postcode: '3283', region: 'Waikato', code: 'WKO' },
  { postcode: '3283', region: 'Waikato', code: 'WKO' },
  { postcode: '3284', region: 'Waikato', code: 'WKO' },
  { postcode: '3285', region: 'Waikato', code: 'WKO' },
  { postcode: '3285', region: 'Waikato', code: 'WKO' },
  { postcode: '3286', region: 'Waikato', code: 'WKO' },
  { postcode: '3286', region: 'Waikato', code: 'WKO' },
  { postcode: '3287', region: 'Waikato', code: 'WKO' },
  { postcode: '3288', region: 'Waikato', code: 'WKO' },
  { postcode: '3288', region: 'Waikato', code: 'WKO' },
  { postcode: '3289', region: 'Waikato', code: 'WKO' },
  { postcode: '3289', region: 'Waikato', code: 'WKO' },
  { postcode: '3290', region: 'Waikato', code: 'WKO' },
  { postcode: '3290', region: 'Waikato', code: 'WKO' },
  { postcode: '3290', region: 'Waikato', code: 'WKO' },
  { postcode: '3293', region: 'Waikato', code: 'WKO' },
  { postcode: '9410', region: 'Otago', code: 'OTA' },
  { postcode: '9442', region: 'Otago', code: 'OTA' },
  { postcode: '7334', region: 'Canterbury', code: 'CAN' },
  { postcode: '7360', region: 'Canterbury', code: 'CAN' },
  { postcode: '7863', region: 'West Coast', code: 'WTC' },
  { postcode: '7884', region: 'West Coast', code: 'WTC' },
  { postcode: '0204', region: 'Northland', code: 'NTL' },
  { postcode: '4120', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4122', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4153', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4154', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4155', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4156', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4159', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4171', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4171', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4172', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4174', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4175', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4178', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4178', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4179', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4180', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4102', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '3451', region: 'Waikato', code: 'WKO' },
  { postcode: '7100', region: 'Marlborough', code: 'MBH' },
  { postcode: '7150', region: 'Marlborough', code: 'MBH' },
  { postcode: '7178', region: 'Marlborough', code: 'MBH' },
  { postcode: '4122', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4130', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4150', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4157', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4294', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4295', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4295', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '7348', region: 'Canterbury', code: 'CAN' },
  { postcode: '7385', region: 'Canterbury', code: 'CAN' },
  { postcode: '4610', region: 'Taranaki', code: 'TKI' },
  { postcode: '4614', region: 'Taranaki', code: 'TKI' },
  { postcode: '4640', region: 'Taranaki', code: 'TKI' },
  { postcode: '4671', region: 'Taranaki', code: 'TKI' },
  { postcode: '4672', region: 'Taranaki', code: 'TKI' },
  { postcode: '4673', region: 'Taranaki', code: 'TKI' },
  { postcode: '4674', region: 'Taranaki', code: 'TKI' },
  { postcode: '4675', region: 'Taranaki', code: 'TKI' },
  { postcode: '4678', region: 'Taranaki', code: 'TKI' },
  { postcode: '4679', region: 'Taranaki', code: 'TKI' },
  { postcode: '4679', region: 'Taranaki', code: 'TKI' },
  { postcode: '7822', region: 'West Coast', code: 'WTC' },
  { postcode: '7867', region: 'West Coast', code: 'WTC' },
  { postcode: '0800', region: 'Auckland', code: 'AUK' },
  { postcode: '0840', region: 'Auckland', code: 'AUK' },
  { postcode: '0874', region: 'Auckland', code: 'AUK' },
  { postcode: '0875', region: 'Auckland', code: 'AUK' },
  { postcode: '0781', region: 'Auckland', code: 'AUK' },
  { postcode: '0782', region: 'Auckland', code: 'AUK' },
  { postcode: '4054', region: 'Gisborne', code: 'GIS' },
  { postcode: '3579', region: 'Waikato', code: 'WKO' },
  { postcode: '0114', region: 'Northland', code: 'NTL' },
  { postcode: '0150', region: 'Northland', code: 'NTL' },
  { postcode: '0181', region: 'Northland', code: 'NTL' },
  { postcode: '0182', region: 'Northland', code: 'NTL' },
  { postcode: '0182', region: 'Northland', code: 'NTL' },
  { postcode: '0184', region: 'Northland', code: 'NTL' },
  { postcode: '0184', region: 'Northland', code: 'NTL' },
  { postcode: '4845', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '7747', region: 'Canterbury', code: 'CAN' },
  { postcode: '7810', region: 'West Coast', code: 'WTC' },
  { postcode: '7811', region: 'West Coast', code: 'WTC' },
  { postcode: '7842', region: 'West Coast', code: 'WTC' },
  { postcode: '7881', region: 'West Coast', code: 'WTC' },
  { postcode: '7882', region: 'West Coast', code: 'WTC' },
  { postcode: '7883', region: 'West Coast', code: 'WTC' },
  { postcode: '7544', region: 'Canterbury', code: 'CAN' },
  { postcode: '3262', region: 'Waikato', code: 'WKO' },
  { postcode: '0460', region: 'Northland', code: 'NTL' },
  { postcode: '2571', region: 'Auckland', code: 'AUK' },
  { postcode: '4730', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4745', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4781', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4782', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4783', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4783', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4784', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4785', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4786', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '3700', region: 'Waikato', code: 'WKO' },
  { postcode: '3740', region: 'Waikato', code: 'WKO' },
  { postcode: '3771', region: 'Waikato', code: 'WKO' },
  { postcode: '3772', region: 'Waikato', code: 'WKO' },
  { postcode: '2254', region: 'Auckland', code: 'AUK' },
  { postcode: '7846', region: 'West Coast', code: 'WTC' },
  { postcode: '7855', region: 'West Coast', code: 'WTC' },
  { postcode: '4330', region: 'Taranaki', code: 'TKI' },
  { postcode: '4347', region: 'Taranaki', code: 'TKI' },
  { postcode: '4386', region: 'Taranaki', code: 'TKI' },
  { postcode: '4387', region: 'Taranaki', code: 'TKI' },
  { postcode: '4387', region: 'Taranaki', code: 'TKI' },
  { postcode: '4388', region: 'Taranaki', code: 'TKI' },
  { postcode: '4389', region: 'Taranaki', code: 'TKI' },
  { postcode: '4390', region: 'Taranaki', code: 'TKI' },
  { postcode: '4390', region: 'Taranaki', code: 'TKI' },
  { postcode: '9810', region: 'Southland', code: 'STL' },
  { postcode: '9812', region: 'Southland', code: 'STL' },
  { postcode: '9840', region: 'Southland', code: 'STL' },
  { postcode: '9841', region: 'Southland', code: 'STL' },
  { postcode: '9843', region: 'Southland', code: 'STL' },
  { postcode: '9844', region: 'Southland', code: 'STL' },
  { postcode: '9845', region: 'Southland', code: 'STL' },
  { postcode: '9871', region: 'Southland', code: 'STL' },
  { postcode: '9871', region: 'Southland', code: 'STL' },
  { postcode: '9872', region: 'Southland', code: 'STL' },
  { postcode: '9872', region: 'Southland', code: 'STL' },
  { postcode: '9874', region: 'Southland', code: 'STL' },
  { postcode: '9874', region: 'Southland', code: 'STL' },
  { postcode: '9875', region: 'Southland', code: 'STL' },
  { postcode: '9876', region: 'Southland', code: 'STL' },
  { postcode: '9876', region: 'Southland', code: 'STL' },
  { postcode: '9877', region: 'Southland', code: 'STL' },
  { postcode: '9877', region: 'Southland', code: 'STL' },
  { postcode: '9879', region: 'Southland', code: 'STL' },
  { postcode: '9879', region: 'Southland', code: 'STL' },
  { postcode: '0448', region: 'Northland', code: 'NTL' },
  { postcode: '0478', region: 'Northland', code: 'NTL' },
  { postcode: '0479', region: 'Northland', code: 'NTL' },
  { postcode: '7630', region: 'Canterbury', code: 'CAN' },
  { postcode: '7644', region: 'Canterbury', code: 'CAN' },
  { postcode: '7691', region: 'Canterbury', code: 'CAN' },
  { postcode: '7692', region: 'Canterbury', code: 'CAN' },
  { postcode: '0405', region: 'Northland', code: 'NTL' },
  { postcode: '0440', region: 'Northland', code: 'NTL' },
  { postcode: '0472', region: 'Northland', code: 'NTL' },
  { postcode: '0473', region: 'Northland', code: 'NTL' },
  { postcode: '0474', region: 'Northland', code: 'NTL' },
  { postcode: '0474', region: 'Northland', code: 'NTL' },
  { postcode: '0474', region: 'Northland', code: 'NTL' },
  { postcode: '7260', region: 'Canterbury', code: 'CAN' },
  { postcode: '7300', region: 'Canterbury', code: 'CAN' },
  { postcode: '7340', region: 'Canterbury', code: 'CAN' },
  { postcode: '7371', region: 'Canterbury', code: 'CAN' },
  { postcode: '7373', region: 'Canterbury', code: 'CAN' },
  { postcode: '7373', region: 'Canterbury', code: 'CAN' },
  { postcode: '7374', region: 'Canterbury', code: 'CAN' },
  { postcode: '7630', region: 'Canterbury', code: 'CAN' },
  { postcode: '0410', region: 'Northland', code: 'NTL' },
  { postcode: '0441', region: 'Northland', code: 'NTL' },
  { postcode: '0481', region: 'Northland', code: 'NTL' },
  { postcode: '0482', region: 'Northland', code: 'NTL' },
  { postcode: '0483', region: 'Northland', code: 'NTL' },
  { postcode: '0484', region: 'Northland', code: 'NTL' },
  { postcode: '9210', region: 'Otago', code: 'OTA' },
  { postcode: '9281', region: 'Otago', code: 'OTA' },
  { postcode: '9282', region: 'Otago', code: 'OTA' },
  { postcode: '0542', region: 'Northland', code: 'NTL' },
  { postcode: '0573', region: 'Northland', code: 'NTL' },
  { postcode: '0185', region: 'Northland', code: 'NTL' },
  { postcode: '4642', region: 'Taranaki', code: 'TKI' },
  { postcode: '7864', region: 'West Coast', code: 'WTC' },
  { postcode: '7893', region: 'West Coast', code: 'WTC' },
  { postcode: '6972', region: 'Wellington', code: 'WGN' },
  { postcode: '3129', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3166', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3170', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3177', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3178', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3181', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '0843', region: 'Auckland', code: 'AUK' },
  { postcode: '0871', region: 'Auckland', code: 'AUK' },
  { postcode: '0873', region: 'Auckland', code: 'AUK' },
  { postcode: '0210', region: 'Northland', code: 'NTL' },
  { postcode: '0243', region: 'Northland', code: 'NTL' },
  { postcode: '0281', region: 'Northland', code: 'NTL' },
  { postcode: '0282', region: 'Northland', code: 'NTL' },
  { postcode: '0283', region: 'Northland', code: 'NTL' },
  { postcode: '3127', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3169', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3843', region: 'Waikato', code: 'WKO' },
  { postcode: '3889', region: 'Waikato', code: 'WKO' },
  { postcode: '0230', region: 'Northland', code: 'NTL' },
  { postcode: '0245', region: 'Northland', code: 'NTL' },
  { postcode: '0293', region: 'Northland', code: 'NTL' },
  { postcode: '0294', region: 'Northland', code: 'NTL' },
  { postcode: '0295', region: 'Northland', code: 'NTL' },
  { postcode: '4744', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4774', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4774', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '9748', region: 'Otago', code: 'OTA' },
  { postcode: '7543', region: 'Canterbury', code: 'CAN' },
  { postcode: '0453', region: 'Northland', code: 'NTL' },
  { postcode: '0491', region: 'Northland', code: 'NTL' },
  { postcode: '0492', region: 'Northland', code: 'NTL' },
  { postcode: '4188', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4188', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '7832', region: 'West Coast', code: 'WTC' },
  { postcode: '7861', region: 'West Coast', code: 'WTC' },
  { postcode: '7875', region: 'West Coast', code: 'WTC' },
  { postcode: '7875', region: 'West Coast', code: 'WTC' },
  { postcode: '0810', region: 'Auckland', code: 'AUK' },
  { postcode: '0841', region: 'Auckland', code: 'AUK' },
  { postcode: '0891', region: 'Auckland', code: 'AUK' },
  { postcode: '0892', region: 'Auckland', code: 'AUK' },
  { postcode: '9435', region: 'Canterbury', code: 'CAN' },
  { postcode: '9446', region: 'Canterbury', code: 'CAN' },
  { postcode: '9498', region: 'Canterbury', code: 'CAN' },
  { postcode: '9498', region: 'Canterbury', code: 'CAN' },
  { postcode: '9345', region: 'Otago', code: 'OTA' },
  { postcode: '7945', region: 'Canterbury', code: 'CAN' },
  { postcode: '9532', region: 'Otago', code: 'OTA' },
  { postcode: '9543', region: 'Otago', code: 'OTA' },
  { postcode: '9591', region: 'Otago', code: 'OTA' },
  { postcode: '9593', region: 'Otago', code: 'OTA' },
  { postcode: '7632', region: 'Canterbury', code: 'CAN' },
  { postcode: '7656', region: 'Canterbury', code: 'CAN' },
  { postcode: '7682', region: 'Canterbury', code: 'CAN' },
  { postcode: '7683', region: 'Canterbury', code: 'CAN' },
  { postcode: '0947', region: 'Auckland', code: 'AUK' },
  { postcode: '7446', region: 'Canterbury', code: 'CAN' },
  { postcode: '5510', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '5540', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '5570', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '5571', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '5572', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '5573', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '5573', region: 'Wellington', code: 'WGN' },
  { postcode: '5574', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '5575', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '7608', region: 'Canterbury', code: 'CAN' },
  { postcode: '7640', region: 'Canterbury', code: 'CAN' },
  { postcode: '7647', region: 'Canterbury', code: 'CAN' },
  { postcode: '7546', region: 'Canterbury', code: 'CAN' },
  { postcode: '7591', region: 'Canterbury', code: 'CAN' },
  { postcode: '5010', region: 'Wellington', code: 'WGN' },
  { postcode: '5011', region: 'Wellington', code: 'WGN' },
  { postcode: '5012', region: 'Wellington', code: 'WGN' },
  { postcode: '5013', region: 'Wellington', code: 'WGN' },
  { postcode: '5014', region: 'Wellington', code: 'WGN' },
  { postcode: '5018', region: 'Wellington', code: 'WGN' },
  { postcode: '5019', region: 'Wellington', code: 'WGN' },
  { postcode: '5019', region: 'Wellington', code: 'WGN' },
  { postcode: '5040', region: 'Wellington', code: 'WGN' },
  { postcode: '5041', region: 'Wellington', code: 'WGN' },
  { postcode: '5042', region: 'Wellington', code: 'WGN' },
  { postcode: '5044', region: 'Wellington', code: 'WGN' },
  { postcode: '5045', region: 'Wellington', code: 'WGN' },
  { postcode: '5047', region: 'Wellington', code: 'WGN' },
  { postcode: '5048', region: 'Wellington', code: 'WGN' },
  { postcode: '5049', region: 'Wellington', code: 'WGN' },
  { postcode: '5050', region: 'Wellington', code: 'WGN' },
  { postcode: '5141', region: 'Wellington', code: 'WGN' },
  { postcode: '9730', region: 'Southland', code: 'STL' },
  { postcode: '9742', region: 'Southland', code: 'STL' },
  { postcode: '9792', region: 'Southland', code: 'STL' },
  { postcode: '9793', region: 'Southland', code: 'STL' },
  { postcode: '9794', region: 'Southland', code: 'STL' },
  { postcode: '8082', region: 'Canterbury', code: 'CAN' },
  { postcode: '8841', region: 'Canterbury', code: 'CAN' },
  { postcode: '8971', region: 'Canterbury', code: 'CAN' },
  { postcode: '4166', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '3945', region: 'Waikato', code: 'WKO' },
  { postcode: '3978', region: 'Waikato', code: 'WKO' },
  { postcode: '9346', region: 'Otago', code: 'OTA' },
  { postcode: '3020', region: 'Waikato', code: 'WKO' },
  { postcode: '3020', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3049', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '4612', region: 'Taranaki', code: 'TKI' },
  { postcode: '4641', region: 'Taranaki', code: 'TKI' },
  { postcode: '5541', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '9643', region: 'Southland', code: 'STL' },
  { postcode: '3421', region: 'Waikato', code: 'WKO' },
  { postcode: '3445', region: 'Waikato', code: 'WKO' },
  { postcode: '3492', region: 'Waikato', code: 'WKO' },
  { postcode: '3492', region: 'Waikato', code: 'WKO' },
  { postcode: '3492', region: 'Waikato', code: 'WKO' },
  { postcode: '4746', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4797', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4797', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '0505', region: 'Northland', code: 'NTL' },
  { postcode: '0540', region: 'Northland', code: 'NTL' },
  { postcode: '0541', region: 'Northland', code: 'NTL' },
  { postcode: '0420', region: 'Northland', code: 'NTL' },
  { postcode: '0442', region: 'Northland', code: 'NTL' },
  { postcode: '0494', region: 'Northland', code: 'NTL' },
  { postcode: '2576', region: 'Auckland', code: 'AUK' },
  { postcode: '4053', region: 'Gisborne', code: 'GIS' },
  { postcode: '4078', region: 'Gisborne', code: 'GIS' },
  { postcode: '7005', region: 'Tasman', code: 'TAS' },
  { postcode: '7048', region: 'Tasman', code: 'TAS' },
  { postcode: '5711', region: 'Wellington', code: 'WGN' },
  { postcode: '5741', region: 'Wellington', code: 'WGN' },
  { postcode: '5781', region: 'Wellington', code: 'WGN' },
  { postcode: '5782', region: 'Wellington', code: 'WGN' },
  { postcode: '5783', region: 'Wellington', code: 'WGN' },
  { postcode: '5784', region: 'Wellington', code: 'WGN' },
  { postcode: '4710', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4741', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4787', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4788', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4789', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '7062', region: 'West Coast', code: 'WTC' },
  { postcode: '5810', region: 'Wellington', code: 'WGN' },
  { postcode: '5840', region: 'Wellington', code: 'WGN' },
  { postcode: '5842', region: 'Wellington', code: 'WGN' },
  { postcode: '5846', region: 'Wellington', code: 'WGN' },
  { postcode: '5871', region: 'Wellington', code: 'WGN' },
  { postcode: '5872', region: 'Wellington', code: 'WGN' },
  { postcode: '5872', region: 'Wellington', code: 'WGN' },
  { postcode: '5881', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '5881', region: 'Wellington', code: 'WGN' },
  { postcode: '5882', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '5882', region: 'Wellington', code: 'WGN' },
  { postcode: '5883', region: 'Wellington', code: 'WGN' },
  { postcode: '5883', region: 'Wellington', code: 'WGN' },
  { postcode: '5884', region: 'Wellington', code: 'WGN' },
  { postcode: '5884', region: 'Wellington', code: 'WGN' },
  { postcode: '5884', region: 'Wellington', code: 'WGN' },
  { postcode: '5885', region: 'Wellington', code: 'WGN' },
  { postcode: '5886', region: 'Wellington', code: 'WGN' },
  { postcode: '5887', region: 'Wellington', code: 'WGN' },
  { postcode: '5888', region: 'Wellington', code: 'WGN' },
  { postcode: '5889', region: 'Wellington', code: 'WGN' },
  { postcode: '5890', region: 'Wellington', code: 'WGN' },
  { postcode: '5890', region: 'Wellington', code: 'WGN' },
  { postcode: '0948', region: 'Auckland', code: 'AUK' },
  { postcode: '0593', region: 'Northland', code: 'NTL' },
  { postcode: '0594', region: 'Northland', code: 'NTL' },
  { postcode: '3400', region: 'Waikato', code: 'WKO' },
  { postcode: '3440', region: 'Waikato', code: 'WKO' },
  { postcode: '3471', region: 'Waikato', code: 'WKO' },
  { postcode: '3472', region: 'Waikato', code: 'WKO' },
  { postcode: '3473', region: 'Waikato', code: 'WKO' },
  { postcode: '3473', region: 'Waikato', code: 'WKO' },
  { postcode: '3260', region: 'Waikato', code: 'WKO' },
  { postcode: '3168', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '9356', region: 'Southland', code: 'STL' },
  { postcode: '9712', region: 'Southland', code: 'STL' },
  { postcode: '4051', region: 'Gisborne', code: 'GIS' },
  { postcode: '4075', region: 'Gisborne', code: 'GIS' },
  { postcode: '3995', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '0146', region: 'Northland', code: 'NTL' },
  { postcode: '0152', region: 'Northland', code: 'NTL' },
  { postcode: '0520', region: 'Northland', code: 'NTL' },
  { postcode: '0547', region: 'Northland', code: 'NTL' },
  { postcode: '0583', region: 'Northland', code: 'NTL' },
  { postcode: '0587', region: 'Northland', code: 'NTL' },
  { postcode: '0588', region: 'Northland', code: 'NTL' },
  { postcode: '0589', region: 'Northland', code: 'NTL' },
  { postcode: '2474', region: 'Waikato', code: 'WKO' },
  { postcode: '2441', region: 'Waikato', code: 'WKO' },
  { postcode: '7730', region: 'Canterbury', code: 'CAN' },
  { postcode: '7745', region: 'Canterbury', code: 'CAN' },
  { postcode: '7791', region: 'Canterbury', code: 'CAN' },
  { postcode: '9067', region: 'Otago', code: 'OTA' },
  { postcode: '9596', region: 'Otago', code: 'OTA' },
  { postcode: '9597', region: 'Otago', code: 'OTA' },
  { postcode: '9598', region: 'Otago', code: 'OTA' },
  { postcode: '9598', region: 'Otago', code: 'OTA' },
  { postcode: '9646', region: 'Southland', code: 'STL' },
  { postcode: '9544', region: 'Otago', code: 'OTA' },
  { postcode: '9220', region: 'Otago', code: 'OTA' },
  { postcode: '9241', region: 'Otago', code: 'OTA' },
  { postcode: '9291', region: 'Otago', code: 'OTA' },
  { postcode: '9292', region: 'Otago', code: 'OTA' },
  { postcode: '7845', region: 'West Coast', code: 'WTC' },
  { postcode: '0211', region: 'Northland', code: 'NTL' },
  { postcode: '0244', region: 'Northland', code: 'NTL' },
  { postcode: '4350', region: 'Waikato', code: 'WKO' },
  { postcode: '4376', region: 'Taranaki', code: 'TKI' },
  { postcode: '4376', region: 'Waikato', code: 'WKO' },
  { postcode: '3300', region: 'Waikato', code: 'WKO' },
  { postcode: '3340', region: 'Waikato', code: 'WKO' },
  { postcode: '3371', region: 'Waikato', code: 'WKO' },
  { postcode: '3371', region: 'Waikato', code: 'WKO' },
  { postcode: '3372', region: 'Waikato', code: 'WKO' },
  { postcode: '3372', region: 'Waikato', code: 'WKO' },
  { postcode: '3373', region: 'Waikato', code: 'WKO' },
  { postcode: '3373', region: 'Waikato', code: 'WKO' },
  { postcode: '3373', region: 'Waikato', code: 'WKO' },
  { postcode: '3374', region: 'Waikato', code: 'WKO' },
  { postcode: '3375', region: 'Waikato', code: 'WKO' },
  { postcode: '3375', region: 'Waikato', code: 'WKO' },
  { postcode: '9024', region: 'Otago', code: 'OTA' },
  { postcode: '9053', region: 'Otago', code: 'OTA' },
  { postcode: '9092', region: 'Otago', code: 'OTA' },
  { postcode: '9747', region: 'Southland', code: 'STL' },
  { postcode: '4052', region: 'Gisborne', code: 'GIS' },
  { postcode: '7120', region: 'Tasman', code: 'TAS' },
  { postcode: '7143', region: 'Tasman', code: 'TAS' },
  { postcode: '7196', region: 'Tasman', code: 'TAS' },
  { postcode: '7197', region: 'Tasman', code: 'TAS' },
  { postcode: '7198', region: 'Tasman', code: 'TAS' },
  { postcode: '3116', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3118', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3149', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3150', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3152', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3951', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '7007', region: 'Tasman', code: 'TAS' },
  { postcode: '7049', region: 'Tasman', code: 'TAS' },
  { postcode: '7077', region: 'Tasman', code: 'TAS' },
  { postcode: '3025', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3062', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3079', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '4104', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4110', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4112', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4140', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4141', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4142', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4143', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4144', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4145', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4149', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4151', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4181', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4182', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4182', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4183', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4183', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4184', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4186', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '9354', region: 'Otago', code: 'OTA' },
  { postcode: '3948', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '7010', region: 'Nelson', code: 'NSN' },
  { postcode: '7011', region: 'Nelson', code: 'NSN' },
  { postcode: '7040', region: 'Nelson', code: 'NSN' },
  { postcode: '7041', region: 'Nelson', code: 'NSN' },
  { postcode: '7042', region: 'Nelson', code: 'NSN' },
  { postcode: '7043', region: 'Nelson', code: 'NSN' },
  { postcode: '7044', region: 'Nelson', code: 'NSN' },
  { postcode: '7045', region: 'Nelson', code: 'NSN' },
  { postcode: '7047', region: 'Nelson', code: 'NSN' },
  { postcode: '7071', region: 'Nelson', code: 'NSN' },
  { postcode: '7072', region: 'Tasman', code: 'TAS' },
  { postcode: '0772', region: 'Auckland', code: 'AUK' },
  { postcode: '4310', region: 'Taranaki', code: 'TKI' },
  { postcode: '4312', region: 'Taranaki', code: 'TKI' },
  { postcode: '4340', region: 'Taranaki', code: 'TKI' },
  { postcode: '4341', region: 'Taranaki', code: 'TKI' },
  { postcode: '4343', region: 'Taranaki', code: 'TKI' },
  { postcode: '4344', region: 'Taranaki', code: 'TKI' },
  { postcode: '4351', region: 'Taranaki', code: 'TKI' },
  { postcode: '4360', region: 'Taranaki', code: 'TKI' },
  { postcode: '4361', region: 'Taranaki', code: 'TKI' },
  { postcode: '4362', region: 'Taranaki', code: 'TKI' },
  { postcode: '4371', region: 'Taranaki', code: 'TKI' },
  { postcode: '4372', region: 'Taranaki', code: 'TKI' },
  { postcode: '4373', region: 'Taranaki', code: 'TKI' },
  { postcode: '4374', region: 'Taranaki', code: 'TKI' },
  { postcode: '4381', region: 'Taranaki', code: 'TKI' },
  { postcode: '4381', region: 'Taranaki', code: 'TKI' },
  { postcode: '7824', region: 'West Coast', code: 'WTC' },
  { postcode: '3720', region: 'Waikato', code: 'WKO' },
  { postcode: '3742', region: 'Waikato', code: 'WKO' },
  { postcode: '3793', region: 'Waikato', code: 'WKO' },
  { postcode: '3794', region: 'Waikato', code: 'WKO' },
  { postcode: '3503', region: 'Waikato', code: 'WKO' },
  { postcode: '3541', region: 'Waikato', code: 'WKO' },
  { postcode: '3597', region: 'Waikato', code: 'WKO' },
  { postcode: '0154', region: 'Northland', code: 'NTL' },
  { postcode: '9630', region: 'Southland', code: 'STL' },
  { postcode: '9644', region: 'Southland', code: 'STL' },
  { postcode: '4943', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4974', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4165', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4198', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4314', region: 'Taranaki', code: 'TKI' },
  { postcode: '4345', region: 'Taranaki', code: 'TKI' },
  { postcode: '9400', region: 'Otago', code: 'OTA' },
  { postcode: '9401', region: 'Otago', code: 'OTA' },
  { postcode: '9444', region: 'Otago', code: 'OTA' },
  { postcode: '9491', region: 'Otago', code: 'OTA' },
  { postcode: '9491', region: 'Canterbury', code: 'CAN' },
  { postcode: '9492', region: 'Otago', code: 'OTA' },
  { postcode: '9493', region: 'Otago', code: 'OTA' },
  { postcode: '9494', region: 'Canterbury', code: 'CAN' },
  { postcode: '9494', region: 'Otago', code: 'OTA' },
  { postcode: '9495', region: 'Otago', code: 'OTA' },
  { postcode: '9635', region: 'Southland', code: 'STL' },
  { postcode: '4625', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4660', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4691', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '3803', region: 'Waikato', code: 'WKO' },
  { postcode: '3881', region: 'Waikato', code: 'WKO' },
  { postcode: '3882', region: 'Waikato', code: 'WKO' },
  { postcode: '3883', region: 'Waikato', code: 'WKO' },
  { postcode: '3784', region: 'Waikato', code: 'WKO' },
  { postcode: '3784', region: 'Waikato', code: 'WKO' },
  { postcode: '3121', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3161', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3926', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '3947', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '3980', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '3980', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '0447', region: 'Northland', code: 'NTL' },
  { postcode: '0475', region: 'Northland', code: 'NTL' },
  { postcode: '0476', region: 'Northland', code: 'NTL' },
  { postcode: '4335', region: 'Taranaki', code: 'TKI' },
  { postcode: '4348', region: 'Taranaki', code: 'TKI' },
  { postcode: '9352', region: 'Otago', code: 'OTA' },
  { postcode: '9376', region: 'Otago', code: 'OTA' },
  { postcode: '9377', region: 'Otago', code: 'OTA' },
  { postcode: '0444', region: 'Northland', code: 'NTL' },
  { postcode: '9448', region: 'Canterbury', code: 'CAN' },
  { postcode: '3114', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3154', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '0118', region: 'Northland', code: 'NTL' },
  { postcode: '0192', region: 'Northland', code: 'NTL' },
  { postcode: '4244', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4278', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4279', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '3997', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '3885', region: 'Waikato', code: 'WKO' },
  { postcode: '3886', region: 'Waikato', code: 'WKO' },
  { postcode: '3886', region: 'Waikato', code: 'WKO' },
  { postcode: '0445', region: 'Northland', code: 'NTL' },
  { postcode: '3122', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3162', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3197', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3198', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3198', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3199', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3199', region: 'Gisborne', code: 'GIS' },
  { postcode: '0200', region: 'Northland', code: 'NTL' },
  { postcode: '0241', region: 'Northland', code: 'NTL' },
  { postcode: '4616', region: 'Taranaki', code: 'TKI' },
  { postcode: '4645', region: 'Taranaki', code: 'TKI' },
  { postcode: '4649', region: 'Taranaki', code: 'TKI' },
  { postcode: '4681', region: 'Taranaki', code: 'TKI' },
  { postcode: '4682', region: 'Taranaki', code: 'TKI' },
  { postcode: '4684', region: 'Taranaki', code: 'TKI' },
  { postcode: '4685', region: 'Taranaki', code: 'TKI' },
  { postcode: '2252', region: 'Auckland', code: 'AUK' },
  { postcode: '0931', region: 'Auckland', code: 'AUK' },
  { postcode: '0946', region: 'Auckland', code: 'AUK' },
  { postcode: '5512', region: 'Wellington', code: 'WGN' },
  { postcode: '5542', region: 'Wellington', code: 'WGN' },
  { postcode: '5581', region: 'Wellington', code: 'WGN' },
  { postcode: '5582', region: 'Wellington', code: 'WGN' },
  { postcode: '5583', region: 'Wellington', code: 'WGN' },
  { postcode: '5583', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4202', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4241', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4276', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4276', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4277', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '9610', region: 'Southland', code: 'STL' },
  { postcode: '9641', region: 'Southland', code: 'STL' },
  { postcode: '9682', region: 'Southland', code: 'STL' },
  { postcode: '9683', region: 'Southland', code: 'STL' },
  { postcode: '9689', region: 'Southland', code: 'STL' },
  { postcode: '9412', region: 'Canterbury', code: 'CAN' },
  { postcode: '9447', region: 'Canterbury', code: 'CAN' },
  { postcode: '3900', region: 'Waikato', code: 'WKO' },
  { postcode: '3940', region: 'Waikato', code: 'WKO' },
  { postcode: '3972', region: 'Waikato', code: 'WKO' },
  { postcode: '3973', region: 'Waikato', code: 'WKO' },
  { postcode: '3974', region: 'Waikato', code: 'WKO' },
  { postcode: '3975', region: 'Waikato', code: 'WKO' },
  { postcode: '3975', region: 'Waikato', code: 'WKO' },
  { postcode: '3976', region: 'Waikato', code: 'WKO' },
  { postcode: '3976', region: 'Waikato', code: 'WKO' },
  { postcode: '3977', region: 'Waikato', code: 'WKO' },
  { postcode: '3977', region: 'Waikato', code: 'WKO' },
  { postcode: '9386', region: 'Otago', code: 'OTA' },
  { postcode: '9387', region: 'Otago', code: 'OTA' },
  { postcode: '9019', region: 'Otago', code: 'OTA' },
  { postcode: '9062', region: 'Otago', code: 'OTA' },
  { postcode: '9073', region: 'Otago', code: 'OTA' },
  { postcode: '9073', region: 'Otago', code: 'OTA' },
  { postcode: '9074', region: 'Otago', code: 'OTA' },
  { postcode: '9074', region: 'Otago', code: 'OTA' },
  { postcode: '9535', region: 'Otago', code: 'OTA' },
  { postcode: '9546', region: 'Otago', code: 'OTA' },
  { postcode: '9585', region: 'Otago', code: 'OTA' },
  { postcode: '9586', region: 'Otago', code: 'OTA' },
  { postcode: '3989', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '3990', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '7430', region: 'Canterbury', code: 'CAN' },
  { postcode: '7443', region: 'Canterbury', code: 'CAN' },
  { postcode: '7495', region: 'Canterbury', code: 'CAN' },
  { postcode: '5032', region: 'Wellington', code: 'WGN' },
  { postcode: '5034', region: 'Wellington', code: 'WGN' },
  { postcode: '5258', region: 'Wellington', code: 'WGN' },
  { postcode: '3600', region: 'Waikato', code: 'WKO' },
  { postcode: '3640', region: 'Waikato', code: 'WKO' },
  { postcode: '3671', region: 'Waikato', code: 'WKO' },
  { postcode: '3672', region: 'Waikato', code: 'WKO' },
  { postcode: '3673', region: 'Waikato', code: 'WKO' },
  { postcode: '3673', region: 'Waikato', code: 'WKO' },
  { postcode: '3674', region: 'Waikato', code: 'WKO' },
  { postcode: '3674', region: 'Waikato', code: 'WKO' },
  { postcode: '4910', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4941', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4981', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4982', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4983', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4984', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4985', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4986', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4987', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4988', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4989', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '0200', region: 'Northland', code: 'NTL' },
  { postcode: '0204', region: 'Northland', code: 'NTL' },
  { postcode: '0247', region: 'Northland', code: 'NTL' },
  { postcode: '9430', region: 'Otago', code: 'OTA' },
  { postcode: '9443', region: 'Otago', code: 'OTA' },
  { postcode: '9481', region: 'Otago', code: 'OTA' },
  { postcode: '9482', region: 'Otago', code: 'OTA' },
  { postcode: '9483', region: 'Otago', code: 'OTA' },
  { postcode: '4410', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4412', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4412', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4414', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4440', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4441', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4442', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4443', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4444', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4445', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4446', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4447', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4448', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4470', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4470', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4471', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4471', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4472', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4473', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4474', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4474', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4475', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4475', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4476', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4477', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4477', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4477', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4478', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4478', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4479', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4481', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4481', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4779', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4820', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4866', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '2110', region: 'Auckland', code: 'AUK' },
  { postcode: '2112', region: 'Auckland', code: 'AUK' },
  { postcode: '2113', region: 'Auckland', code: 'AUK' },
  { postcode: '2166', region: 'Auckland', code: 'AUK' },
  { postcode: '2244', region: 'Auckland', code: 'AUK' },
  { postcode: '2580', region: 'Auckland', code: 'AUK' },
  { postcode: '2582', region: 'Auckland', code: 'AUK' },
  { postcode: '2583', region: 'Waikato', code: 'WKO' },
  { postcode: '2583', region: 'Auckland', code: 'AUK' },
  { postcode: '2584', region: 'Auckland', code: 'AUK' },
  { postcode: '2585', region: 'Auckland', code: 'AUK' },
  { postcode: '3118', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3151', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '0543', region: 'Northland', code: 'NTL' },
  { postcode: '0571', region: 'Northland', code: 'NTL' },
  { postcode: '0830', region: 'Auckland', code: 'AUK' },
  { postcode: '0844', region: 'Auckland', code: 'AUK' },
  { postcode: '5032', region: 'Wellington', code: 'WGN' },
  { postcode: '5036', region: 'Wellington', code: 'WGN' },
  { postcode: '5252', region: 'Wellington', code: 'WGN' },
  { postcode: '5254', region: 'Wellington', code: 'WGN' },
  { postcode: '5255', region: 'Wellington', code: 'WGN' },
  { postcode: '7912', region: 'Canterbury', code: 'CAN' },
  { postcode: '4520', region: 'Taranaki', code: 'TKI' },
  { postcode: '4545', region: 'Taranaki', code: 'TKI' },
  { postcode: '4597', region: 'Taranaki', code: 'TKI' },
  { postcode: '4598', region: 'Taranaki', code: 'TKI' },
  { postcode: '2344', region: 'Auckland', code: 'AUK' },
  { postcode: '4045', region: 'Gisborne', code: 'GIS' },
  { postcode: '3546', region: 'Waikato', code: 'WKO' },
  { postcode: '7990', region: 'Canterbury', code: 'CAN' },
  { postcode: '7612', region: 'Canterbury', code: 'CAN' },
  { postcode: '7648', region: 'Canterbury', code: 'CAN' },
  { postcode: '7220', region: 'Marlborough', code: 'MBH' },
  { postcode: '7250', region: 'Marlborough', code: 'MBH' },
  { postcode: '7251', region: 'Marlborough', code: 'MBH' },
  { postcode: '7281', region: 'Marlborough', code: 'MBH' },
  { postcode: '7282', region: 'Marlborough', code: 'MBH' },
  { postcode: '7284', region: 'Marlborough', code: 'MBH' },
  { postcode: '0646', region: 'Auckland', code: 'AUK' },
  { postcode: '3912', region: 'Waikato', code: 'WKO' },
  { postcode: '3942', region: 'Waikato', code: 'WKO' },
  { postcode: '3970', region: 'Waikato', code: 'WKO' },
  { postcode: '3971', region: 'Waikato', code: 'WKO' },
  { postcode: '3802', region: 'Waikato', code: 'WKO' },
  { postcode: '3844', region: 'Waikato', code: 'WKO' },
  { postcode: '7903', region: 'Canterbury', code: 'CAN' },
  { postcode: '7947', region: 'Canterbury', code: 'CAN' },
  { postcode: '7982', region: 'Canterbury', code: 'CAN' },
  { postcode: '7982', region: 'Canterbury', code: 'CAN' },
  { postcode: '7983', region: 'Canterbury', code: 'CAN' },
  { postcode: '2402', region: 'Waikato', code: 'WKO' },
  { postcode: '2440', region: 'Waikato', code: 'WKO' },
  { postcode: '2471', region: 'Waikato', code: 'WKO' },
  { postcode: '2471', region: 'Waikato', code: 'WKO' },
  { postcode: '2471', region: 'Auckland', code: 'AUK' },
  { postcode: '2472', region: 'Auckland', code: 'AUK' },
  { postcode: '2472', region: 'Waikato', code: 'WKO' },
  { postcode: '2473', region: 'Waikato', code: 'WKO' },
  { postcode: '2473', region: 'Waikato', code: 'WKO' },
  { postcode: '4944', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4990', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4991', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4992', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4245', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4291', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4292', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4293', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '5022', region: 'Wellington', code: 'WGN' },
  { postcode: '5024', region: 'Wellington', code: 'WGN' },
  { postcode: '5026', region: 'Wellington', code: 'WGN' },
  { postcode: '5240', region: 'Wellington', code: 'WGN' },
  { postcode: '5242', region: 'Wellington', code: 'WGN' },
  { postcode: '5245', region: 'Wellington', code: 'WGN' },
  { postcode: '5247', region: 'Wellington', code: 'WGN' },
  { postcode: '5381', region: 'Wellington', code: 'WGN' },
  { postcode: '5381', region: 'Wellington', code: 'WGN' },
  { postcode: '9023', region: 'Otago', code: 'OTA' },
  { postcode: '9050', region: 'Otago', code: 'OTA' },
  { postcode: '9081', region: 'Otago', code: 'OTA' },
  { postcode: '9082', region: 'Otago', code: 'OTA' },
  { postcode: '7604', region: 'Canterbury', code: 'CAN' },
  { postcode: '0951', region: 'Auckland', code: 'AUK' },
  { postcode: '3880', region: 'Waikato', code: 'WKO' },
  { postcode: '3880', region: 'Waikato', code: 'WKO' },
  { postcode: '3880', region: 'Waikato', code: 'WKO' },
  { postcode: '2120', region: 'Waikato', code: 'WKO' },
  { postcode: '2120', region: 'Auckland', code: 'AUK' },
  { postcode: '2340', region: 'Auckland', code: 'AUK' },
  { postcode: '2676', region: 'Auckland', code: 'AUK' },
  { postcode: '2677', region: 'Auckland', code: 'AUK' },
  { postcode: '2677', region: 'Waikato', code: 'WKO' },
  { postcode: '2678', region: 'Waikato', code: 'WKO' },
  { postcode: '2678', region: 'Auckland', code: 'AUK' },
  { postcode: '2679', region: 'Auckland', code: 'AUK' },
  { postcode: '5026', region: 'Wellington', code: 'WGN' },
  { postcode: '3411', region: 'Waikato', code: 'WKO' },
  { postcode: '3415', region: 'Waikato', code: 'WKO' },
  { postcode: '3443', region: 'Waikato', code: 'WKO' },
  { postcode: '3481', region: 'Waikato', code: 'WKO' },
  { postcode: '3482', region: 'Waikato', code: 'WKO' },
  { postcode: '3483', region: 'Waikato', code: 'WKO' },
  { postcode: '9300', region: 'Otago', code: 'OTA' },
  { postcode: '9302', region: 'Otago', code: 'OTA' },
  { postcode: '9304', region: 'Otago', code: 'OTA' },
  { postcode: '9348', region: 'Otago', code: 'OTA' },
  { postcode: '9349', region: 'Otago', code: 'OTA' },
  { postcode: '9371', region: 'Otago', code: 'OTA' },
  { postcode: '4632', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4646', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4694', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4696', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '3225', region: 'Waikato', code: 'WKO' },
  { postcode: '3265', region: 'Waikato', code: 'WKO' },
  { postcode: '3295', region: 'Waikato', code: 'WKO' },
  { postcode: '3296', region: 'Waikato', code: 'WKO' },
  { postcode: '3297', region: 'Waikato', code: 'WKO' },
  { postcode: '7145', region: 'Marlborough', code: 'MBH' },
  { postcode: '7192', region: 'Marlborough', code: 'MBH' },
  { postcode: '7194', region: 'Marlborough', code: 'MBH' },
  { postcode: '7195', region: 'Marlborough', code: 'MBH' },
  { postcode: '7710', region: 'Canterbury', code: 'CAN' },
  { postcode: '7743', region: 'Canterbury', code: 'CAN' },
  { postcode: '7781', region: 'Canterbury', code: 'CAN' },
  { postcode: '7782', region: 'Canterbury', code: 'CAN' },
  { postcode: '7782', region: 'Canterbury', code: 'CAN' },
  { postcode: '7783', region: 'Canterbury', code: 'CAN' },
  { postcode: '7784', region: 'Canterbury', code: 'CAN' },
  { postcode: '9332', region: 'Otago', code: 'OTA' },
  { postcode: '9353', region: 'Otago', code: 'OTA' },
  { postcode: '9395', region: 'Otago', code: 'OTA' },
  { postcode: '9396', region: 'Otago', code: 'OTA' },
  { postcode: '9397', region: 'Otago', code: 'OTA' },
  { postcode: '9397', region: 'Otago', code: 'OTA' },
  { postcode: '9398', region: 'Otago', code: 'OTA' },
  { postcode: '7400', region: 'Canterbury', code: 'CAN' },
  { postcode: '7440', region: 'Canterbury', code: 'CAN' },
  { postcode: '7471', region: 'Canterbury', code: 'CAN' },
  { postcode: '7472', region: 'Canterbury', code: 'CAN' },
  { postcode: '7472', region: 'Canterbury', code: 'CAN' },
  { postcode: '7473', region: 'Canterbury', code: 'CAN' },
  { postcode: '7475', region: 'Canterbury', code: 'CAN' },
  { postcode: '7476', region: 'Canterbury', code: 'CAN' },
  { postcode: '7477', region: 'Canterbury', code: 'CAN' },
  { postcode: '7477', region: 'Canterbury', code: 'CAN' },
  { postcode: '4548', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4189', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '0443', region: 'Northland', code: 'NTL' },
  { postcode: '0932', region: 'Auckland', code: 'AUK' },
  { postcode: '0945', region: 'Auckland', code: 'AUK' },
  { postcode: '7830', region: 'West Coast', code: 'WTC' },
  { postcode: '7851', region: 'West Coast', code: 'WTC' },
  { postcode: '7895', region: 'West Coast', code: 'WTC' },
  { postcode: '7204', region: 'Marlborough', code: 'MBH' },
  { postcode: '7243', region: 'Marlborough', code: 'MBH' },
  { postcode: '3060', region: 'Waikato', code: 'WKO' },
  { postcode: '3081', region: 'Waikato', code: 'WKO' },
  { postcode: '3081', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3081', region: 'Waikato', code: 'WKO' },
  { postcode: '3083', region: 'Waikato', code: 'WKO' },
  { postcode: '3083', region: 'Waikato', code: 'WKO' },
  { postcode: '4780', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '7020', region: 'Tasman', code: 'TAS' },
  { postcode: '7020', region: 'Nelson', code: 'NSN' },
  { postcode: '7050', region: 'Tasman', code: 'TAS' },
  { postcode: '7081', region: 'Tasman', code: 'TAS' },
  { postcode: '7081', region: 'Nelson', code: 'NSN' },
  { postcode: '0820', region: 'Auckland', code: 'AUK' },
  { postcode: '9744', region: 'Southland', code: 'STL' },
  { postcode: '9822', region: 'Southland', code: 'STL' },
  { postcode: '9847', region: 'Southland', code: 'STL' },
  { postcode: '9881', region: 'Southland', code: 'STL' },
  { postcode: '9883', region: 'Southland', code: 'STL' },
  { postcode: '7146', region: 'Tasman', code: 'TAS' },
  { postcode: '7614', region: 'Canterbury', code: 'CAN' },
  { postcode: '7615', region: 'Canterbury', code: 'CAN' },
  { postcode: '7643', region: 'Canterbury', code: 'CAN' },
  { postcode: '7650', region: 'Canterbury', code: 'CAN' },
  { postcode: '7658', region: 'Canterbury', code: 'CAN' },
  { postcode: '4865', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '7812', region: 'West Coast', code: 'WTC' },
  { postcode: '7860', region: 'West Coast', code: 'WTC' },
  { postcode: '7885', region: 'West Coast', code: 'WTC' },
  { postcode: '7379', region: 'Canterbury', code: 'CAN' },
  { postcode: '3010', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3015', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3040', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3041', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3042', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3043', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3044', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3045', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3046', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3047', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3048', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3072', region: 'Waikato', code: 'WKO' },
  { postcode: '3072', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3072', region: 'Waikato', code: 'WKO' },
  { postcode: '3073', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3073', region: 'Waikato', code: 'WKO' },
  { postcode: '3074', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3074', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3076', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3077', region: 'Waikato', code: 'WKO' },
  { postcode: '3077', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3096', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3097', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '9441', region: 'Otago', code: 'OTA' },
  { postcode: '9500', region: 'Otago', code: 'OTA' },
  { postcode: '9571', region: 'Otago', code: 'OTA' },
  { postcode: '9572', region: 'Otago', code: 'OTA' },
  { postcode: '9572', region: 'Otago', code: 'OTA' },
  { postcode: '0116', region: 'Northland', code: 'NTL' },
  { postcode: '0151', region: 'Northland', code: 'NTL' },
  { postcode: '4032', region: 'Gisborne', code: 'GIS' },
  { postcode: '4043', region: 'Gisborne', code: 'GIS' },
  { postcode: '4081', region: 'Gisborne', code: 'GIS' },
  { postcode: '4082', region: 'Gisborne', code: 'GIS' },
  { postcode: '4083', region: 'Gisborne', code: 'GIS' },
  { postcode: '0530', region: 'Northland', code: 'NTL' },
  { postcode: '0549', region: 'Northland', code: 'NTL' },
  { postcode: '0591', region: 'Northland', code: 'NTL' },
  { postcode: '0592', region: 'Northland', code: 'NTL' },
  { postcode: '7803', region: 'West Coast', code: 'WTC' },
  { postcode: '7841', region: 'West Coast', code: 'WTC' },
  { postcode: '7873', region: 'West Coast', code: 'WTC' },
  { postcode: '7873', region: 'West Coast', code: 'WTC' },
  { postcode: '0202', region: 'Northland', code: 'NTL' },
  { postcode: '0242', region: 'Northland', code: 'NTL' },
  { postcode: '0272', region: 'Northland', code: 'NTL' },
  { postcode: '7053', region: 'Tasman', code: 'TAS' },
  { postcode: '4817', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4862', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '7210', region: 'Marlborough', code: 'MBH' },
  { postcode: '7247', region: 'Marlborough', code: 'MBH' },
  { postcode: '7285', region: 'Marlborough', code: 'MBH' },
  { postcode: '7445', region: 'Canterbury', code: 'CAN' },
  { postcode: '4821', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4860', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '7500', region: 'Canterbury', code: 'CAN' },
  { postcode: '7580', region: 'Canterbury', code: 'CAN' },
  { postcode: '0932', region: 'Auckland', code: 'AUK' },
  { postcode: '0944', region: 'Auckland', code: 'AUK' },
  { postcode: '0950', region: 'Auckland', code: 'AUK' },
  { postcode: '0992', region: 'Auckland', code: 'AUK' },
  { postcode: '0993', region: 'Auckland', code: 'AUK' },
  { postcode: '0994', region: 'Auckland', code: 'AUK' },
  { postcode: '0920', region: 'Auckland', code: 'AUK' },
  { postcode: '0942', region: 'Auckland', code: 'AUK' },
  { postcode: '7602', region: 'Canterbury', code: 'CAN' },
  { postcode: '7642', region: 'Canterbury', code: 'CAN' },
  { postcode: '7202', region: 'Marlborough', code: 'MBH' },
  { postcode: '7244', region: 'Marlborough', code: 'MBH' },
  { postcode: '7649', region: 'Canterbury', code: 'CAN' },
  { postcode: '7681', region: 'Canterbury', code: 'CAN' },
  { postcode: '7847', region: 'West Coast', code: 'WTC' },
  { postcode: '7616', region: 'Canterbury', code: 'CAN' },
  { postcode: '7988', region: 'Canterbury', code: 'CAN' },
  { postcode: '9846', region: 'Southland', code: 'STL' },
  { postcode: '9231', region: 'Otago', code: 'OTA' },
  { postcode: '4332', region: 'Taranaki', code: 'TKI' },
  { postcode: '4352', region: 'Taranaki', code: 'TKI' },
  { postcode: '4390', region: 'Taranaki', code: 'TKI' },
  { postcode: '4391', region: 'Taranaki', code: 'TKI' },
  { postcode: '4391', region: 'Taranaki', code: 'TKI' },
  { postcode: '4392', region: 'Taranaki', code: 'TKI' },
  { postcode: '4392', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4393', region: 'Taranaki', code: 'TKI' },
  { postcode: '4393', region: 'Taranaki', code: 'TKI' },
  { postcode: '4394', region: 'Taranaki', code: 'TKI' },
  { postcode: '4394', region: 'Taranaki', code: 'TKI' },
  { postcode: '4395', region: 'Taranaki', code: 'TKI' },
  { postcode: '4396', region: 'Taranaki', code: 'TKI' },
  { postcode: '4396', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4397', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4397', region: 'Taranaki', code: 'TKI' },
  { postcode: '4397', region: 'Taranaki', code: 'TKI' },
  { postcode: '7645', region: 'Canterbury', code: 'CAN' },
  { postcode: '4720', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4742', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4791', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4791', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4792', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4792', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4793', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4794', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4795', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4795', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4796', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '0420', region: 'Northland', code: 'NTL' },
  { postcode: '0450', region: 'Northland', code: 'NTL' },
  { postcode: '3508', region: 'Waikato', code: 'WKO' },
  { postcode: '3544', region: 'Waikato', code: 'WKO' },
  { postcode: '7110', region: 'Tasman', code: 'TAS' },
  { postcode: '7142', region: 'Tasman', code: 'TAS' },
  { postcode: '7182', region: 'Tasman', code: 'TAS' },
  { postcode: '7183', region: 'Tasman', code: 'TAS' },
  { postcode: '2105', region: 'Auckland', code: 'AUK' },
  { postcode: '2110', region: 'Auckland', code: 'AUK' },
  { postcode: '2112', region: 'Auckland', code: 'AUK' },
  { postcode: '2245', region: 'Auckland', code: 'AUK' },
  { postcode: '2246', region: 'Auckland', code: 'AUK' },
  { postcode: '4203', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4243', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4286', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4287', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4287', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4288', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '3123', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3163', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '4822', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '0381', region: 'Northland', code: 'NTL' },
  { postcode: '9522', region: 'Otago', code: 'OTA' },
  { postcode: '9542', region: 'Otago', code: 'OTA' },
  { postcode: '9587', region: 'Otago', code: 'OTA' },
  { postcode: '9587', region: 'Otago', code: 'OTA' },
  { postcode: '7055', region: 'Tasman', code: 'TAS' },
  { postcode: '9347', region: 'Otago', code: 'OTA' },
  { postcode: '3920', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '3924', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '3946', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '3991', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '3992', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '3993', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '3994', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '3996', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '3721', region: 'Waikato', code: 'WKO' },
  { postcode: '3791', region: 'Waikato', code: 'WKO' },
  { postcode: '3792', region: 'Waikato', code: 'WKO' },
  { postcode: '3330', region: 'Waikato', code: 'WKO' },
  { postcode: '3332', region: 'Waikato', code: 'WKO' },
  { postcode: '3350', region: 'Waikato', code: 'WKO' },
  { postcode: '3351', region: 'Waikato', code: 'WKO' },
  { postcode: '3352', region: 'Waikato', code: 'WKO' },
  { postcode: '3377', region: 'Waikato', code: 'WKO' },
  { postcode: '3378', region: 'Waikato', code: 'WKO' },
  { postcode: '3379', region: 'Waikato', code: 'WKO' },
  { postcode: '3379', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3379', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '3384', region: 'Waikato', code: 'WKO' },
  { postcode: '3385', region: 'Waikato', code: 'WKO' },
  { postcode: '3110', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3112', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3141', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3142', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3143', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3144', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3145', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3146', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3147', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3148', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3155', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3156', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3171', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3171', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3172', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3173', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3173', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3174', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3175', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3175', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3176', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3176', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3179', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3180', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '9600', region: 'Southland', code: 'STL' },
  { postcode: '9640', region: 'Southland', code: 'STL' },
  { postcode: '9672', region: 'Southland', code: 'STL' },
  { postcode: '9679', region: 'Southland', code: 'STL' },
  { postcode: '4050', region: 'Gisborne', code: 'GIS' },
  { postcode: '3320', region: 'Waikato', code: 'WKO' },
  { postcode: '3342', region: 'Waikato', code: 'WKO' },
  { postcode: '3391', region: 'Waikato', code: 'WKO' },
  { postcode: '3392', region: 'Waikato', code: 'WKO' },
  { postcode: '3392', region: 'Waikato', code: 'WKO' },
  { postcode: '3393', region: 'Waikato', code: 'WKO' },
  { postcode: '3800', region: 'Waikato', code: 'WKO' },
  { postcode: '3840', region: 'Waikato', code: 'WKO' },
  { postcode: '3841', region: 'Waikato', code: 'WKO' },
  { postcode: '3872', region: 'Waikato', code: 'WKO' },
  { postcode: '3873', region: 'Waikato', code: 'WKO' },
  { postcode: '3873', region: 'Waikato', code: 'WKO' },
  { postcode: '3874', region: 'Waikato', code: 'WKO' },
  { postcode: '3874', region: 'Waikato', code: 'WKO' },
  { postcode: '3875', region: 'Waikato', code: 'WKO' },
  { postcode: '3876', region: 'Waikato', code: 'WKO' },
  { postcode: '3876', region: 'Waikato', code: 'WKO' },
  { postcode: '3877', region: 'Waikato', code: 'WKO' },
  { postcode: '3877', region: 'Waikato', code: 'WKO' },
  { postcode: '3878', region: 'Waikato', code: 'WKO' },
  { postcode: '3879', region: 'Waikato', code: 'WKO' },
  { postcode: '4102', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '5544', region: 'Wellington', code: 'WGN' },
  { postcode: '4022', region: 'Gisborne', code: 'GIS' },
  { postcode: '4042', region: 'Gisborne', code: 'GIS' },
  { postcode: '4091', region: 'Gisborne', code: 'GIS' },
  { postcode: '4092', region: 'Gisborne', code: 'GIS' },
  { postcode: '4093', region: 'Gisborne', code: 'GIS' },
  { postcode: '4094', region: 'Gisborne', code: 'GIS' },
  { postcode: '3710', region: 'Waikato', code: 'WKO' },
  { postcode: '3741', region: 'Waikato', code: 'WKO' },
  { postcode: '3781', region: 'Waikato', code: 'WKO' },
  { postcode: '3782', region: 'Waikato', code: 'WKO' },
  { postcode: '0341', region: 'Northland', code: 'NTL' },
  { postcode: '0391', region: 'Northland', code: 'NTL' },
  { postcode: '3910', region: 'Waikato', code: 'WKO' },
  { postcode: '3941', region: 'Waikato', code: 'WKO' },
  { postcode: '3981', region: 'Waikato', code: 'WKO' },
  { postcode: '3982', region: 'Waikato', code: 'WKO' },
  { postcode: '3982', region: 'Waikato', code: 'WKO' },
  { postcode: '3983', region: 'Waikato', code: 'WKO' },
  { postcode: '3985', region: 'Waikato', code: 'WKO' },
  { postcode: '3986', region: 'Waikato', code: 'WKO' },
  { postcode: '3986', region: 'Waikato', code: 'WKO' },
  { postcode: '3987', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '3987', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '3987', region: 'Waikato', code: 'WKO' },
  { postcode: '3988', region: 'Waikato', code: 'WKO' },
  { postcode: '3894', region: 'Waikato', code: 'WKO' },
  { postcode: '3895', region: 'Waikato', code: 'WKO' },
  { postcode: '4048', region: 'Gisborne', code: 'GIS' },
  { postcode: '3119', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3153', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3182', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3183', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3186', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3186', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3187', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3187', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3188', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3189', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3167', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3266', region: 'Waikato', code: 'WKO' },
  { postcode: '7920', region: 'Canterbury', code: 'CAN' },
  { postcode: '7948', region: 'Canterbury', code: 'CAN' },
  { postcode: '7985', region: 'Canterbury', code: 'CAN' },
  { postcode: '7986', region: 'Canterbury', code: 'CAN' },
  { postcode: '3500', region: 'Waikato', code: 'WKO' },
  { postcode: '3540', region: 'Waikato', code: 'WKO' },
  { postcode: '3574', region: 'Waikato', code: 'WKO' },
  { postcode: '3575', region: 'Waikato', code: 'WKO' },
  { postcode: '3576', region: 'Waikato', code: 'WKO' },
  { postcode: '3577', region: 'Waikato', code: 'WKO' },
  { postcode: '3578', region: 'Waikato', code: 'WKO' },
  { postcode: '7630', region: 'Canterbury', code: 'CAN' },
  { postcode: '4049', region: 'Gisborne', code: 'GIS' },
  { postcode: '4086', region: 'Gisborne', code: 'GIS' },
  { postcode: '4087', region: 'Gisborne', code: 'GIS' },
  { postcode: '7910', region: 'Canterbury', code: 'CAN' },
  { postcode: '7940', region: 'Canterbury', code: 'CAN' },
  { postcode: '7941', region: 'Canterbury', code: 'CAN' },
  { postcode: '7942', region: 'Canterbury', code: 'CAN' },
  { postcode: '7943', region: 'Canterbury', code: 'CAN' },
  { postcode: '7971', region: 'Canterbury', code: 'CAN' },
  { postcode: '7971', region: 'Canterbury', code: 'CAN' },
  { postcode: '7972', region: 'Canterbury', code: 'CAN' },
  { postcode: '7972', region: 'Canterbury', code: 'CAN' },
  { postcode: '7973', region: 'Canterbury', code: 'CAN' },
  { postcode: '7974', region: 'Canterbury', code: 'CAN' },
  { postcode: '7975', region: 'Canterbury', code: 'CAN' },
  { postcode: '4044', region: 'Gisborne', code: 'GIS' },
  { postcode: '5894', region: 'Wellington', code: 'WGN' },
  { postcode: '3410', region: 'Waikato', code: 'WKO' },
  { postcode: '3442', region: 'Waikato', code: 'WKO' },
  { postcode: '3484', region: 'Waikato', code: 'WKO' },
  { postcode: '3484', region: 'Waikato', code: 'WKO' },
  { postcode: '3485', region: 'Waikato', code: 'WKO' },
  { postcode: '3485', region: 'Waikato', code: 'WKO' },
  { postcode: '9884', region: 'Southland', code: 'STL' },
  { postcode: '4864', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4047', region: 'Gisborne', code: 'GIS' },
  { postcode: '4079', region: 'Gisborne', code: 'GIS' },
  { postcode: '3420', region: 'Waikato', code: 'WKO' },
  { postcode: '3444', region: 'Waikato', code: 'WKO' },
  { postcode: '3491', region: 'Waikato', code: 'WKO' },
  { postcode: '4046', region: 'Gisborne', code: 'GIS' },
  { postcode: '4077', region: 'Gisborne', code: 'GIS' },
  { postcode: '4164', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '2121', region: 'Waikato', code: 'WKO' },
  { postcode: '2342', region: 'Waikato', code: 'WKO' },
  { postcode: '2693', region: 'Waikato', code: 'WKO' },
  { postcode: '2694', region: 'Waikato', code: 'WKO' },
  { postcode: '2695', region: 'Waikato', code: 'WKO' },
  { postcode: '2696', region: 'Waikato', code: 'WKO' },
  { postcode: '2697', region: 'Waikato', code: 'WKO' },
  { postcode: '7246', region: 'Marlborough', code: 'MBH' },
  { postcode: '9620', region: 'Southland', code: 'STL' },
  { postcode: '9642', region: 'Southland', code: 'STL' },
  { postcode: '9691', region: 'Southland', code: 'STL' },
  { postcode: '4547', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '3334', region: 'Waikato', code: 'WKO' },
  { postcode: '3353', region: 'Waikato', code: 'WKO' },
  { postcode: '3381', region: 'Waikato', code: 'WKO' },
  { postcode: '3382', region: 'Waikato', code: 'WKO' },
  { postcode: '3545', region: 'Waikato', code: 'WKO' },
  { postcode: '4162', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '0153', region: 'Northland', code: 'NTL' },
  { postcode: '7901', region: 'Canterbury', code: 'CAN' },
  { postcode: '7944', region: 'Canterbury', code: 'CAN' },
  { postcode: '5018', region: 'Wellington', code: 'WGN' },
  { postcode: '5019', region: 'Wellington', code: 'WGN' },
  { postcode: '5140', region: 'Wellington', code: 'WGN' },
  { postcode: '5142', region: 'Wellington', code: 'WGN' },
  { postcode: '5143', region: 'Wellington', code: 'WGN' },
  { postcode: '5371', region: 'Wellington', code: 'WGN' },
  { postcode: '5372', region: 'Wellington', code: 'WGN' },
  { postcode: '7144', region: 'Tasman', code: 'TAS' },
  { postcode: '7173', region: 'Northland', code: 'NTL' },
  { postcode: '7173', region: 'Tasman', code: 'TAS' },
  { postcode: '7175', region: 'Tasman', code: 'TAS' },
  { postcode: '4349', region: 'Taranaki', code: 'TKI' },
  { postcode: '4375', region: 'Taranaki', code: 'TKI' },
  { postcode: '4377', region: 'Taranaki', code: 'TKI' },
  { postcode: '4378', region: 'Taranaki', code: 'TKI' },
  { postcode: '4379', region: 'Taranaki', code: 'TKI' },
  { postcode: '7500', region: 'Canterbury', code: 'CAN' },
  { postcode: '3391', region: 'Waikato', code: 'WKO' },
  { postcode: '3401', region: 'Waikato', code: 'WKO' },
  { postcode: '3441', region: 'Waikato', code: 'WKO' },
  { postcode: '3474', region: 'Waikato', code: 'WKO' },
  { postcode: '7332', region: 'Canterbury', code: 'CAN' },
  { postcode: '7343', region: 'Canterbury', code: 'CAN' },
  { postcode: '7395', region: 'Canterbury', code: 'CAN' },
  { postcode: '2345', region: 'Auckland', code: 'AUK' },
  { postcode: '1081', region: 'Auckland', code: 'AUK' },
  { postcode: '1840', region: 'Auckland', code: 'AUK' },
  { postcode: '1841', region: 'Auckland', code: 'AUK' },
  { postcode: '1842', region: 'Auckland', code: 'AUK' },
  { postcode: '1843', region: 'Auckland', code: 'AUK' },
  { postcode: '1971', region: 'Auckland', code: 'AUK' },
  { postcode: '3610', region: 'Waikato', code: 'WKO' },
  { postcode: '3641', region: 'Waikato', code: 'WKO' },
  { postcode: '3681', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3681', region: 'Waikato', code: 'WKO' },
  { postcode: '3682', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3682', region: 'Waikato', code: 'WKO' },
  { postcode: '3611', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3642', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '9243', region: 'Otago', code: 'OTA' },
  { postcode: '9745', region: 'Southland', code: 'STL' },
  { postcode: '9778', region: 'Southland', code: 'STL' },
  { postcode: '5036', region: 'Wellington', code: 'WGN' },
  { postcode: '5250', region: 'Wellington', code: 'WGN' },
  { postcode: '5391', region: 'Wellington', code: 'WGN' },
  { postcode: '7420', region: 'Canterbury', code: 'CAN' },
  { postcode: '7442', region: 'Canterbury', code: 'CAN' },
  { postcode: '7491', region: 'Canterbury', code: 'CAN' },
  { postcode: '9471', region: 'Otago', code: 'OTA' },
  { postcode: '9471', region: 'Otago', code: 'OTA' },
  { postcode: '9472', region: 'Otago', code: 'OTA' },
  { postcode: '9472', region: 'Otago', code: 'OTA' },
  { postcode: '9510', region: 'Otago', code: 'OTA' },
  { postcode: '9541', region: 'Otago', code: 'OTA' },
  { postcode: '7402', region: 'Canterbury', code: 'CAN' },
  { postcode: '7448', region: 'Canterbury', code: 'CAN' },
  { postcode: '0446', region: 'Northland', code: 'NTL' },
  { postcode: '3164', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3196', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '7848', region: 'West Coast', code: 'WTC' },
  { postcode: '7924', region: 'Canterbury', code: 'CAN' },
  { postcode: '7960', region: 'Canterbury', code: 'CAN' },
  { postcode: '7977', region: 'Canterbury', code: 'CAN' },
  { postcode: '7978', region: 'Canterbury', code: 'CAN' },
  { postcode: '7979', region: 'Canterbury', code: 'CAN' },
  { postcode: '7980', region: 'Canterbury', code: 'CAN' },
  { postcode: '0812', region: 'Auckland', code: 'AUK' },
  { postcode: '0842', region: 'Auckland', code: 'AUK' },
  { postcode: '0881', region: 'Auckland', code: 'AUK' },
  { postcode: '0882', region: 'Auckland', code: 'AUK' },
  { postcode: '0883', region: 'Auckland', code: 'AUK' },
  { postcode: '3998', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '5373', region: 'Wellington', code: 'WGN' },
  { postcode: '0193', region: 'Northland', code: 'NTL' },
  { postcode: '0193', region: 'Northland', code: 'NTL' },
  { postcode: '4825', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4826', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4861', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '0230', region: 'Northland', code: 'NTL' },
  { postcode: '0246', region: 'Northland', code: 'NTL' },
  { postcode: '7447', region: 'Canterbury', code: 'CAN' },
  { postcode: '4210', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4240', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4271', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4272', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4273', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4274', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4274', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4275', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '0510', region: 'Northland', code: 'NTL' },
  { postcode: '0545', region: 'Northland', code: 'NTL' },
  { postcode: '0582', region: 'Northland', code: 'NTL' },
  { postcode: '4200', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4242', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4281', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4282', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4283', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4284', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4285', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4108', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4160', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4163', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4191', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4193', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4195', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4196', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4197', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '4320', region: 'Taranaki', code: 'TKI' },
  { postcode: '4346', region: 'Taranaki', code: 'TKI' },
  { postcode: '4382', region: 'Taranaki', code: 'TKI' },
  { postcode: '4383', region: 'Taranaki', code: 'TKI' },
  { postcode: '9069', region: 'Otago', code: 'OTA' },
  { postcode: '9085', region: 'Otago', code: 'OTA' },
  { postcode: '3310', region: 'Waikato', code: 'WKO' },
  { postcode: '3341', region: 'Waikato', code: 'WKO' },
  { postcode: '3380', region: 'Waikato', code: 'WKO' },
  { postcode: '3943', region: 'Waikato', code: 'WKO' },
  { postcode: '4549', region: 'Taranaki', code: 'TKI' },
  { postcode: '2123', region: 'Auckland', code: 'AUK' },
  { postcode: '2341', region: 'Auckland', code: 'AUK' },
  { postcode: '2681', region: 'Auckland', code: 'AUK' },
  { postcode: '2681', region: 'Waikato', code: 'WKO' },
  { postcode: '2682', region: 'Waikato', code: 'WKO' },
  { postcode: '2682', region: 'Auckland', code: 'AUK' },
  { postcode: '2683', region: 'Waikato', code: 'WKO' },
  { postcode: '2683', region: 'Auckland', code: 'AUK' },
  { postcode: '2684', region: 'Auckland', code: 'AUK' },
  { postcode: '7025', region: 'Tasman', code: 'TAS' },
  { postcode: '7052', region: 'Tasman', code: 'TAS' },
  { postcode: '7095', region: 'Tasman', code: 'TAS' },
  { postcode: '7096', region: 'Tasman', code: 'TAS' },
  { postcode: '9816', region: 'Southland', code: 'STL' },
  { postcode: '3475', region: 'Waikato', code: 'WKO' },
  { postcode: '9305', region: 'Otago', code: 'OTA' },
  { postcode: '9343', region: 'Otago', code: 'OTA' },
  { postcode: '9344', region: 'Otago', code: 'OTA' },
  { postcode: '9382', region: 'Otago', code: 'OTA' },
  { postcode: '7248', region: 'Marlborough', code: 'MBH' },
  { postcode: '0910', region: 'Auckland', code: 'AUK' },
  { postcode: '0920', region: 'Auckland', code: 'AUK' },
  { postcode: '0941', region: 'Auckland', code: 'AUK' },
  { postcode: '0981', region: 'Auckland', code: 'AUK' },
  { postcode: '0982', region: 'Auckland', code: 'AUK' },
  { postcode: '0983', region: 'Auckland', code: 'AUK' },
  { postcode: '0984', region: 'Auckland', code: 'AUK' },
  { postcode: '0985', region: 'Auckland', code: 'AUK' },
  { postcode: '0986', region: 'Auckland', code: 'AUK' },
  { postcode: '9449', region: 'Otago', code: 'OTA' },
  { postcode: '4510', region: 'Taranaki', code: 'TKI' },
  { postcode: '4544', region: 'Taranaki', code: 'TKI' },
  { postcode: '4591', region: 'Taranaki', code: 'TKI' },
  { postcode: '4592', region: 'Taranaki', code: 'TKI' },
  { postcode: '9355', region: 'Otago', code: 'OTA' },
  { postcode: '5016', region: 'Wellington', code: 'WGN' },
  { postcode: '5028', region: 'Wellington', code: 'WGN' },
  { postcode: '5249', region: 'Wellington', code: 'WGN' },
  { postcode: '6011', region: 'Wellington', code: 'WGN' },
  { postcode: '6012', region: 'Wellington', code: 'WGN' },
  { postcode: '6021', region: 'Wellington', code: 'WGN' },
  { postcode: '6022', region: 'Wellington', code: 'WGN' },
  { postcode: '6023', region: 'Wellington', code: 'WGN' },
  { postcode: '6035', region: 'Wellington', code: 'WGN' },
  { postcode: '6037', region: 'Wellington', code: 'WGN' },
  { postcode: '6140', region: 'Wellington', code: 'WGN' },
  { postcode: '6141', region: 'Wellington', code: 'WGN' },
  { postcode: '6142', region: 'Wellington', code: 'WGN' },
  { postcode: '6143', region: 'Wellington', code: 'WGN' },
  { postcode: '6144', region: 'Wellington', code: 'WGN' },
  { postcode: '6147', region: 'Wellington', code: 'WGN' },
  { postcode: '6150', region: 'Wellington', code: 'WGN' },
  { postcode: '6160', region: 'Wellington', code: 'WGN' },
  { postcode: '6241', region: 'Wellington', code: 'WGN' },
  { postcode: '6242', region: 'Wellington', code: 'WGN' },
  { postcode: '6243', region: 'Wellington', code: 'WGN' },
  { postcode: '6440', region: 'Wellington', code: 'WGN' },
  { postcode: '6441', region: 'Wellington', code: 'WGN' },
  { postcode: '6442', region: 'Wellington', code: 'WGN' },
  { postcode: '6443', region: 'Wellington', code: 'WGN' },
  { postcode: '0900', region: 'Auckland', code: 'AUK' },
  { postcode: '0940', region: 'Auckland', code: 'AUK' },
  { postcode: '0972', region: 'Auckland', code: 'AUK' },
  { postcode: '0973', region: 'Auckland', code: 'AUK' },
  { postcode: '0974', region: 'Auckland', code: 'AUK' },
  { postcode: '0975', region: 'Auckland', code: 'AUK' },
  { postcode: '0975', region: 'Northland', code: 'NTL' },
  { postcode: '0977', region: 'Auckland', code: 'AUK' },
  { postcode: '7618', region: 'Canterbury', code: 'CAN' },
  { postcode: '7825', region: 'West Coast', code: 'WTC' },
  { postcode: '7866', region: 'West Coast', code: 'WTC' },
  { postcode: '7891', region: 'West Coast', code: 'WTC' },
  { postcode: '7892', region: 'West Coast', code: 'WTC' },
  { postcode: '3120', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3158', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3159', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3191', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3192', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3192', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3193', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3194', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3194', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '3194', region: 'Bay of Plenty', code: 'BOP' },
  { postcode: '4161', region: "Hawke's Bay", code: 'HKB' },
  { postcode: '3620', region: 'Waikato', code: 'WKO' },
  { postcode: '3643', region: 'Waikato', code: 'WKO' },
  { postcode: '3691', region: 'Waikato', code: 'WKO' },
  { postcode: '3691', region: 'Waikato', code: 'WKO' },
  { postcode: '4500', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4501', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4541', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4542', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4543', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4571', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4572', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4573', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4573', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4574', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4575', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4576', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4576', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4577', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4577', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4578', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4578', region: 'Taranaki', code: 'TKI' },
  { postcode: '4581', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4581', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4582', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4584', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4585', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4586', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4587', region: 'Taranaki', code: 'TKI' },
  { postcode: '4587', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4588', region: 'Taranaki', code: 'TKI' },
  { postcode: '0930', region: 'Auckland', code: 'AUK' },
  { postcode: '0932', region: 'Auckland', code: 'AUK' },
  { postcode: '0943', region: 'Auckland', code: 'AUK' },
  { postcode: '0952', region: 'Auckland', code: 'AUK' },
  { postcode: '0110', region: 'Northland', code: 'NTL' },
  { postcode: '0112', region: 'Northland', code: 'NTL' },
  { postcode: '0118', region: 'Northland', code: 'NTL' },
  { postcode: '0140', region: 'Northland', code: 'NTL' },
  { postcode: '0141', region: 'Northland', code: 'NTL' },
  { postcode: '0142', region: 'Northland', code: 'NTL' },
  { postcode: '0143', region: 'Northland', code: 'NTL' },
  { postcode: '0144', region: 'Northland', code: 'NTL' },
  { postcode: '0145', region: 'Northland', code: 'NTL' },
  { postcode: '0147', region: 'Northland', code: 'NTL' },
  { postcode: '0148', region: 'Northland', code: 'NTL' },
  { postcode: '0170', region: 'Northland', code: 'NTL' },
  { postcode: '0171', region: 'Northland', code: 'NTL' },
  { postcode: '0172', region: 'Northland', code: 'NTL' },
  { postcode: '0172', region: 'Northland', code: 'NTL' },
  { postcode: '0173', region: 'Northland', code: 'NTL' },
  { postcode: '0174', region: 'Northland', code: 'NTL' },
  { postcode: '0175', region: 'Northland', code: 'NTL' },
  { postcode: '0176', region: 'Northland', code: 'NTL' },
  { postcode: '0178', region: 'Northland', code: 'NTL' },
  { postcode: '0178', region: 'Northland', code: 'NTL' },
  { postcode: '0179', region: 'Northland', code: 'NTL' },
  { postcode: '7857', region: 'West Coast', code: 'WTC' },
  { postcode: '7886', region: 'West Coast', code: 'WTC' },
  { postcode: '3261', region: 'Waikato', code: 'WKO' },
  { postcode: '3646', region: 'Waikato', code: 'WKO' },
  { postcode: '2019', region: 'Auckland', code: 'AUK' },
  { postcode: '2149', region: 'Auckland', code: 'AUK' },
  { postcode: '3510', region: 'Waikato', code: 'WKO' },
  { postcode: '3542', region: 'Waikato', code: 'WKO' },
  { postcode: '3591', region: 'Waikato', code: 'WKO' },
  { postcode: '3592', region: 'Waikato', code: 'WKO' },
  { postcode: '7958', region: 'Canterbury', code: 'CAN' },
  { postcode: '9720', region: 'Southland', code: 'STL' },
  { postcode: '9741', region: 'Southland', code: 'STL' },
  { postcode: '9781', region: 'Southland', code: 'STL' },
  { postcode: '9782', region: 'Southland', code: 'STL' },
  { postcode: '9783', region: 'Southland', code: 'STL' },
  { postcode: '7610', region: 'Canterbury', code: 'CAN' },
  { postcode: '7641', region: 'Canterbury', code: 'CAN' },
  { postcode: '4920', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4945', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4997', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4998', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '4999', region: 'Manawatu-Wanganui', code: 'MWT' },
  { postcode: '9831', region: 'Southland', code: 'STL' },
  { postcode: '9849', region: 'Southland', code: 'STL' },
  { postcode: '9891', region: 'Southland', code: 'STL' },
  { postcode: '9892', region: 'Southland', code: 'STL' },
  { postcode: '9892', region: 'Southland', code: 'STL' },
  { postcode: '9893', region: 'Southland', code: 'STL' },
];
