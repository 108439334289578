import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import {
  ICheckout,
  ICheckoutTotals,
  IShippingAddress, IShippingMethod,
} from "./types/types";
import { storefrontSdk } from "./utils/sdk";
import config from "./utils/config";
import {useParams, useSearchParams} from "react-router-dom";

interface CartContextValues {
  checkout: ICheckout | null;
  cartLoading: boolean;
  totalsLoading: boolean;
  checkoutTotals: ICheckoutTotals | null;
  addDiscountCode: (discountCode: string) => Promise<void>;
  addGiftCard: (giftCardCode: string) => Promise<void>;
  removeGiftCard: () => Promise<void>;
  updateEmail: (email: string | null) => Promise<void>;
  updateCustomerPreferredAddress: (address: IShippingAddress | null) => Promise<void>;
  updateShippingAddress: (address: IShippingAddress | null) => Promise<void>;
  updateBillingAddress: (address: IShippingAddress | null) => Promise<void>;
  removeUserFromCart: () => Promise<void>;
  updateShippingMethod: (method: IShippingMethod) => Promise<void>;
  toggleEmailMarketingOptIn: () => void;
}
const CartContext = createContext<CartContextValues | undefined>(undefined);

export const CartProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [checkout, setCheckout] = useState<ICheckout | null>(null);
  const [cartLoading, setCartLoading] = useState(true);
  const [checkoutTotals, setCheckoutTotals] = useState<ICheckoutTotals | null>(null);
  const [totalsLoading, setTotalsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const queryParams = useParams();
  const [searchParams] = useSearchParams();
  const cartKey = searchParams.get('key');
  const cartId = `gid://shopify/Cart/${queryParams.cartId}?key=${cartKey}`;

  // temporary lint error fix
  useEffect(() => {

  }, [error, setTotalsLoading])

  useEffect(() => {
    async function initializeCheckout() {
      try {
        setCartLoading(true);
        const response = await fetch(`${config.checkoutUrl}/api/fetchCheckout`, {
          method: 'POST',
          body: JSON.stringify({ cartId })
        });

        if (!response.ok) {
          throw new Error('Failed to initialize checkout');
        }

        const data = await response.json();
        setCheckout(data.checkout);
        setCheckoutTotals(data.totals);
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Failed to initialize checkout'));
      } finally {
        setCartLoading(false);
      }
    }

    if (cartId) {
      initializeCheckout();
    }
  }, [cartId]);

  const updateCheckout = async (type: string, data: unknown) => {
    try {
      const response = await fetch(`${config.checkoutUrl}/api/updateCheckout`, {
        method: 'POST',
        body: JSON.stringify({
          cartId,
          update: { type, data }
        })
      });

      if (!response.ok) {
        throw new Error('Failed to update cart');
      }

      const { checkout: updatedCheckout, totals: updatedTotals } = await response.json();
      setCheckout(updatedCheckout);
      setCheckoutTotals(updatedTotals);
    } catch (err) {
      throw err instanceof Error ? err : new Error('Failed to update cart');
    }
  };


  const updateEmail = async (email: string | null) => {
    await updateCheckout('email', { email });
  };

  const updateShippingAddress = async (address: IShippingAddress | null) => {
    await updateCheckout('shipping_address', address);
  };

  const updateBillingAddress = async (address: IShippingAddress | null) => {
    await updateCheckout('billing_address', address);
  };

  const updateShippingMethod = async (method: IShippingMethod) => {
    await updateCheckout('shipping_method', method);
  };

  const addGiftCard = async (code: string) => {
    await updateCheckout('gift_card_code', code);
  };

  const addDiscountCode = async (code: string) => {
    await updateCheckout('discount_code', code);
  };

  const removeGiftCard = async () => {
    await updateCheckout('gift_card_code', null);
  };

  const toggleEmailMarketingOptIn = async () => {
    await updateCheckout('marketing_opt_in', {
      optIn: !checkout?.emailMarketingOptIn
    });
  };

  const removeUserFromCart = async () => {
    const storefront = storefrontSdk();

    // Remove from Shopify cart
    await storefront.updateCartBuyerIdentity({
      buyerIdentity: {
        customerAccessToken: null,
      },
      cartId,
    });
  };

  const contextValue: CartContextValues = {
    checkout,
    checkoutTotals,
    cartLoading,
    totalsLoading,
    addDiscountCode,
    addGiftCard,
    removeGiftCard,
    updateEmail,
    updateCustomerPreferredAddress: (address : IShippingAddress | null) => {
      return new Promise(async (resolve, reject) => {
        resolve();
      })
    },
    updateShippingAddress,
    updateBillingAddress,
    removeUserFromCart,
    updateShippingMethod,
    toggleEmailMarketingOptIn,
  };

  return (
    <CartContext.Provider value={contextValue}>
      {children}
    </CartContext.Provider>
  );
};

export const useCartContext = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCartContext must be used within a CartProvider");
  }
  return context;
};
