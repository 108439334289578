import React, { useState, useEffect } from "react";
import config from "./utils/config";
import formatPrice from "./utils/formatPrice";
import { useCartContext } from "./CartContext";
import { IShippingAddress } from "./types/types";
import SvgLogo from "./SvgLogo";
import LoadingSpinner from "./components/LoadingSpinner";
import StaticMap from "./components/StaticMap";
import {Link} from "react-router-dom";
import BundleItem from "./components/BundleItem";
import LineItem from "./components/LineItem";
import trackingService from "./analytics/trackingService";
import GiftItem from "./components/GiftItem";
import AddressInput from "./components/AddressInput";
import TextInput from "./components/TextInput";
import LoadingButton from "./components/LoadingButton";

interface IPaymentMethodCard {
  brand: string;
  last4?: string;
}

export interface IPaymentMethodData {
  paymentMethod: {
    card: IPaymentMethodCard | null;
  };
  shippingAddress: Partial<IShippingAddress> | null;
}

const Confirmed = () => {
  const [loading, setLoading] = useState(false);
  const [updatingAddress, setUpdatingAddress] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { checkout, checkoutTotals, totalsLoading, updateShippingAddress, updateEmail } = useCartContext();
  const [showAddressForm, setShowAddressForm] = useState<boolean>(false)
  const [newEmailAddress, setNewEmailAddress] = useState<string>("")
  const [newAddress, setNewAddress] = useState<Partial<IShippingAddress> | null>(null)
  const [formErrors, setFormErrors] = useState<Record<string, string[]>>({});

  // temporary lint error fix
  useEffect(() => {

  }, [setLoading, setError, setFormErrors]);

  useEffect(() => {
    const trackPurchaseEvent = async () => {
      if (!checkout || !checkoutTotals) {
        return;
      }
      await trackingService.trackEvent("purchase", {
        checkout: checkout,
        totals: checkoutTotals,
      });
    };

    trackPurchaseEvent();
  }, [checkout, checkoutTotals]);

  if (!checkout) {
    // @TODO show loading spinner or error message?
    return <></>;
  }

  function resetForm() {
    setShowAddressForm(false);
    setNewAddress(null);
    setNewEmailAddress("");
  }

  function isValidEmail(email: string): boolean {
    const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  async function handleAddressUpdate() {
    try {
      setUpdatingAddress(true);
      if (newAddress) {
        await updateShippingAddress(newAddress as IShippingAddress);
      }
      if (newEmailAddress && isValidEmail(newEmailAddress) && newEmailAddress !== checkout?.email) {
        await updateEmail(newEmailAddress);
      }
    } catch (err) {
      console.error(err);
    } finally {
      resetForm();
      setUpdatingAddress(false);
    }
  }

  function formatAddress(address: Partial<IShippingAddress>): string {
    const parts: (string | undefined | null)[] = [
      address.address1,
      address.address2,
      address.city,
      address.province,
      address.country,
      address.zip,
    ];

    return parts
      .filter((part) => part !== undefined && part !== null)
      .join(", ");
  }

  return (
    <div className="bg-white">
      <div
        className="fixed left-0 top-0 hidden h-full w-1/2 bg-gray-50 lg:block"
        aria-hidden="true"
      />
      <div
        className="fixed right-0 top-0 hidden h-full w-1/2 bg-white lg:block"
        aria-hidden="true"
      />
      <div className="relative mx-auto grid max-w-7xl grid-cols-1 gap-x-16 lg:grid-cols-2 lg:px-8 xl:gap-x-48">
        <h1 className="sr-only">Order information</h1>
        <section
          aria-labelledby="summary-heading"
          className="px-4 pb-10 pt-16 sm:px-6 lg:col-start-2 lg:row-start-1 lg:bg-transparent lg:px-0 lg:pb-16"
        >
          {!error && !loading && (
            <div className="mx-auto max-w-lg lg:max-w-none">
              {checkout?.shippingAddress && (
                <div className="py-6">
                  <StaticMap address={formatAddress(checkout.shippingAddress)}/>
                </div>
              )}
              <h2 className="font-bold text-lg">Check your details are correct?</h2>
              <dl className="grid grid-cols-2 gap-x-6 py-6 text-sm">
                <div>
                  <dt className="font-bold text-gray-900">
                    Shipping address
                  </dt>
                  <dd className="mt-2 text-gray-700">
                    <address className="not-italic">
                      {checkout.shippingAddress &&
                        formatAddress(checkout.shippingAddress)
                          .split(",")
                          .map((addressItem: string, index: number) => (
                            <span className="block" key={index}>
                              {addressItem}
                            </span>
                          ))}
                    </address>
                  </dd>
                </div>
                <div>
                  <dt className="font-bold text-gray-900">
                    Billing address
                  </dt>
                  <dd className="mt-2 text-gray-700">
                    <address className="not-italic">
                      {checkout.shippingAddress &&
                        formatAddress(checkout.shippingAddress)
                          .split(",")
                          .map((addressItem: string, index: number) => (
                            <span className="block" key={index}>
                              {addressItem}
                            </span>
                          ))}
                    </address>
                  </dd>
                </div>
                <div className="mt-4">
                  <dt className="font-bold text-gray-900">Contact email</dt>
                  <dd className="mt-2 text-gray-700">
                    <p>{checkout.email}</p>
                  </dd>
                </div>
              </dl>
            </div>
          )}
          <div className="border-t border-gray-200 pt-4">
            {!showAddressForm && (
            <button type="button"
                    onClick={() => setShowAddressForm(true)}
                    className="block w-full text-[15px] font-bold tracking-wider py-3 px-[35px] bg-black text-white text-center cursor-pointer h-auto justify-center items-center box-border rounded-full appearance-none hover:scale-105 duration-200 transition-transform mt-5 sm:leading-7 disabled:bg-gray-300">
              Edit address
            </button>
            )}
            {showAddressForm && (
              <div>
                <TextInput
                  name="email"
                  type="email"
                  label="Email address"
                  placeholder="Email address"
                  autoComplete="email"
                  value={newEmailAddress}
                  errors={formErrors}
                  onChange={(event) => setNewEmailAddress(event.target.value)}
                />
                <AddressInput hideOptIn disableCountry value={{ country: "NZ" }} onChange={setNewAddress}/>
                <LoadingButton loading={updatingAddress}
                               type="button"
                               onClick={handleAddressUpdate}>
                  <span>Save</span>
                </LoadingButton>
                <button type="button" onClick={resetForm} className="w-full mt-4 underline">
                  Cancel
                </button>
              </div>
            )}
          </div>
        </section>
        <div
          className="hidden lg:flex flex-col px-4 pb-36 pt-16 sm:px-6 lg:col-start-1 lg:row-start-1 lg:px-0 lg:pb-16">
          <div className="flex items-center justify-flex-start mb-4 text-lg font-bold text-gray-500">
            <a
              href={config.storeUrl}
              aria-label="Bushbuck Logo"
              className={`flex group items-center justify-center cursor-pointer text-[#111] h-10`}
            >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-900"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                />
              </svg>
              <SvgLogo
                color="inherit"
                className="absolute left-16 transition-opacity duration-200 group-hover:opacity-0"
                style={{width: "140px", height: "36px", margin: "auto"}}
              />
              <span
                className="absolute text-sm left-16 leading-1 hidden duration-200 group-hover:inline transition-opacity">
                Back
              </span>
            </a>
          </div>
          <div className="flex mb-4">
            <div className="mr-3">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67 67" className="w-11 h-11" fill="none">
                <path stroke="#000" strokeLinecap="square" strokeWidth="2.5"
                      d="M43.98 26.773 31.48 42.19l-6.46-6.167"/>
                <path stroke="#000" strokeWidth="3"
                      d="M66.376 33.88C66.442 51.76 52 66.308 34.118 66.373c-17.88.066-32.43-14.376-32.494-32.256C1.558 16.237 16 1.689 33.882 1.624 51.762 1.558 66.312 16 66.376 33.88Z"/>
              </svg>
            </div>
            <div className="w-full flex justify-between">
              <div>
                <h2 className="font-bold text-md pb-1">We've received your order!</h2>
                <Link to={`/checkout/complete/${checkout.cartId.replace('gid://shopify/Cart/', '')}`} className="flex text-[0.9rem] items-center text-secondary-500">
                  View order confirmation
                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" fill="none">
                    <mask id="a" fill="#fff">
                      <path d="m7.253 6.796 3.689 3.935-3.69 3.936 1.216 1.296 4.904-5.232L8.468 5.5 7.253 6.796Z"/>
                    </mask>
                    <path fill="#FE5E39"
                          d="m7.253 6.796 3.689 3.935-3.69 3.936 1.216 1.296 4.904-5.232L8.468 5.5 7.253 6.796Z"/>
                    <path fill="#FE5E39"
                          d="M7.253 6.796 5.064 4.744 3.14 6.796l1.924 2.052 2.189-2.052Zm3.689 3.935 2.189 2.052 1.923-2.052-1.923-2.051-2.189 2.051Zm-3.69 3.936-2.188-2.052-1.924 2.052 1.924 2.051 2.189-2.051Zm1.216 1.296-2.189 2.052 2.189 2.334 2.188-2.334-2.188-2.052Zm4.904-5.232 2.189 2.052 1.924-2.052L15.56 8.68l-2.189 2.051ZM8.468 5.5l2.188-2.052-2.188-2.334-2.189 2.334L8.468 5.5ZM5.064 8.848l3.69 3.935L13.13 8.68 9.44 4.744 5.064 8.848Zm3.69-.168-3.69 3.935 4.377 4.103 3.69-3.935L8.753 8.68Zm-3.69 8.038 1.215 1.297 4.377-4.104-1.215-1.296-4.377 4.103Zm5.592 1.297 4.905-5.232-4.377-4.103-4.905 5.231 4.377 4.104Zm4.905-9.335-4.905-5.232L6.28 7.552l4.905 5.231L15.56 8.68ZM6.279 3.448 5.064 4.744l4.377 4.104 1.215-1.296L6.28 3.448Z"
                          mask="url(#a)"/>
                  </svg>
                </Link>
              </div>
              <div className="text-md font-medium">
                Confirmation #{checkout.checkoutId?.slice(0, 8)}
              </div>
            </div>
          </div>
          <ul className="border-t border-gray-200 pt-4 divide-y divide-gray-200 text-sm font-medium text-gray-900">
            {checkout?.lineItems.map((lineItem) => (
              <LineItem
                key={lineItem.variantId}
                lineItem={lineItem}
                currencyCode={checkout.currencyCode}
              />
            ))}
            {checkout?.bundleItems.map((bundleItem) => (
              <BundleItem
                key={bundleItem.id}
                bundle={bundleItem}
                currencyCode={checkout.currencyCode}
              />
            ))}
            {checkout?.giftItems.map((giftItem) => (
              <GiftItem
                key={giftItem.id}
                giftItem={giftItem}
                currencyCode={checkout.currencyCode}
              />
            ))}
          </ul>

          <dl className="hidden space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-900 lg:block">
            <div className="flex items-center justify-between">
              <dt className="text-gray-600">Cart Total</dt>
              {!totalsLoading && checkoutTotals?.carttotal && (
                <dd>
                  {formatPrice(checkoutTotals.carttotal, checkout.currencyCode)}
                </dd>
              )}
              {totalsLoading && (
                <dd>
                  <LoadingSpinner/>
                </dd>
              )}
            </div>

            <div className="flex items-center justify-between">
              <dt className="text-gray-600">Savings</dt>
              {!totalsLoading && checkoutTotals?.discounts && (
                <dd className="text-gray-900">
                  {formatPrice(checkoutTotals.discounts, checkout.currencyCode)}
                </dd>
              )}
              {totalsLoading && (
                <dd>
                  <LoadingSpinner/>
                </dd>
              )}
            </div>

            <div className="flex items-center justify-between">
              <dt className="text-gray-600">Subtotal</dt>
              {!totalsLoading && checkoutTotals?.subtotal && (
                <dd>
                  {formatPrice(checkoutTotals.subtotal, checkout.currencyCode)}
                </dd>
              )}
              {totalsLoading && (
                <dd>
                  <LoadingSpinner/>
                </dd>
              )}
            </div>

            <div className="flex items-center justify-between">
              <dt className="text-gray-600">Shipping</dt>
              {!totalsLoading &&
                checkout.shippingMethod &&
                checkoutTotals?.shipping && (
                  <dd className="text-gray-900">
                    {formatPrice(
                      checkoutTotals.shipping,
                      checkout.currencyCode,
                    )}
                  </dd>
                )}
              {totalsLoading && (
                <dd>
                  <LoadingSpinner/>
                </dd>
              )}
              {!checkout.shippingMethod && (
                <dd className="text-gray-600">Enter shipping address</dd>
              )}
            </div>

            <div className="flex items-center justify-between border-t border-gray-200 pt-6">
              <dt className="text-base">Total</dt>
              {!totalsLoading && checkoutTotals?.total && (
                <dd className="text-gray-900">
                  {formatPrice(checkoutTotals.total, checkout.currencyCode)}
                </dd>
              )}
              {totalsLoading && (
                <dd>
                  <LoadingSpinner/>
                </dd>
              )}
            </div>
          </dl>
          {!totalsLoading && checkoutTotals?.tax && (
            <span className="text-sm text-gray-700 mt-3 font-light">
              including {formatPrice(checkoutTotals.tax, checkout.currencyCode)}{" "}
              in taxes
            </span>
          )}
          <div className="flex-1"></div>
          <div className="flex justify-center gap-10 mt-16">
            <div className="flex gap-1 text-sm items-center text-gray-400 font-light">
              <span>Powered by</span>
              <svg
                className="text-gray-500"
                fill="currentColor"
                focusable="false"
                width="33"
                height="15"
                role="img"
                aria-labelledby="stripe-title"
              >
                <title id="stripe-title">Stripe</title>
                <g fillRule="evenodd">
                  <path
                    d="M32.956 7.925c0-2.313-1.12-4.138-3.261-4.138-2.15 0-3.451 1.825-3.451 4.12 0 2.719 1.535 4.092 3.74 4.092 1.075 0 1.888-.244 2.502-.587V9.605c-.614.307-1.319.497-2.213.497-.876 0-1.653-.307-1.753-1.373h4.418c0-.118.018-.588.018-.804zm-4.463-.859c0-1.02.624-1.445 1.193-1.445.55 0 1.138.424 1.138 1.445h-2.33zM22.756 3.787c-.885 0-1.454.415-1.77.704l-.118-.56H18.88v10.535l2.259-.48.009-2.556c.325.235.804.57 1.6.57 1.616 0 3.089-1.302 3.089-4.166-.01-2.62-1.5-4.047-3.08-4.047zm-.542 6.225c-.533 0-.85-.19-1.066-.425l-.009-3.352c.235-.262.56-.443 1.075-.443.822 0 1.391.922 1.391 2.105 0 1.211-.56 2.115-1.39 2.115zM18.04 2.766V.932l-2.268.479v1.843zM15.772 3.94h2.268v7.905h-2.268zM13.342 4.609l-.144-.669h-1.952v7.906h2.259V6.488c.533-.696 1.436-.57 1.716-.47V3.94c-.289-.108-1.346-.307-1.879.669zM8.825 1.98l-2.205.47-.009 7.236c0 1.337 1.003 2.322 2.34 2.322.741 0 1.283-.135 1.581-.298V9.876c-.289.117-1.716.533-1.716-.804V5.865h1.716V3.94H8.816l.009-1.96zM2.718 6.235c0-.352.289-.488.767-.488.687 0 1.554.208 2.241.578V4.202a5.958 5.958 0 0 0-2.24-.415c-1.835 0-3.054.957-3.054 2.557 0 2.493 3.433 2.096 3.433 3.17 0 .416-.361.552-.867.552-.75 0-1.708-.307-2.467-.723v2.15c.84.362 1.69.515 2.467.515 1.879 0 3.17-.93 3.17-2.548-.008-2.692-3.45-2.213-3.45-3.225z"></path>
                </g>
              </svg>
            </div>
            <a
              className="text-gray-400 font-light text-sm hover:text-gray-900 underline"
              href={`${config.storeUrl}/pages/privacy-policy`}
            >
              Privacy
            </a>
            <a
              className="text-gray-400 font-light text-sm hover:text-gray-900 underline"
              href={`${config.storeUrl}/pages/returns-exchanges`}
            >
              Returns
            </a>
            <a
              className="text-gray-400 font-light text-sm hover:text-gray-900 underline"
              href={`${config.storeUrl}/pages/contact-us`}
            >
              Support
            </a>
          </div>
        </div>
      </div>
      <div className="flex lg:hidden justify-center gap-10">
        <a
          className="text-gray-400 font-light text-sm hover:text-gray-900 underline"
          href={`${config.storeUrl}/pages/privacy-policy`}
        >
          Privacy
        </a>
        <a
          className="text-gray-400 font-light text-sm hover:text-gray-900 underline"
          href={`${config.storeUrl}/pages/contact-us`}
        >
          Support
        </a>
      </div>
      <div className="flex gap-1 text-sm justify-center mt-4 items-center text-gray-400 font-light">
        <span>Powered by</span>
        <svg
          className="text-gray-500"
          fill="currentColor"
          focusable="false"
          width="33"
          height="15"
          role="img"
          aria-labelledby="stripe-title"
        >
          <title id="stripe-title">Stripe</title>
          <g fillRule="evenodd">
            <path d="M32.956 7.925c0-2.313-1.12-4.138-3.261-4.138-2.15 0-3.451 1.825-3.451 4.12 0 2.719 1.535 4.092 3.74 4.092 1.075 0 1.888-.244 2.502-.587V9.605c-.614.307-1.319.497-2.213.497-.876 0-1.653-.307-1.753-1.373h4.418c0-.118.018-.588.018-.804zm-4.463-.859c0-1.02.624-1.445 1.193-1.445.55 0 1.138.424 1.138 1.445h-2.33zM22.756 3.787c-.885 0-1.454.415-1.77.704l-.118-.56H18.88v10.535l2.259-.48.009-2.556c.325.235.804.57 1.6.57 1.616 0 3.089-1.302 3.089-4.166-.01-2.62-1.5-4.047-3.08-4.047zm-.542 6.225c-.533 0-.85-.19-1.066-.425l-.009-3.352c.235-.262.56-.443 1.075-.443.822 0 1.391.922 1.391 2.105 0 1.211-.56 2.115-1.39 2.115zM18.04 2.766V.932l-2.268.479v1.843zM15.772 3.94h2.268v7.905h-2.268zM13.342 4.609l-.144-.669h-1.952v7.906h2.259V6.488c.533-.696 1.436-.57 1.716-.47V3.94c-.289-.108-1.346-.307-1.879.669zM8.825 1.98l-2.205.47-.009 7.236c0 1.337 1.003 2.322 2.34 2.322.741 0 1.283-.135 1.581-.298V9.876c-.289.117-1.716.533-1.716-.804V5.865h1.716V3.94H8.816l.009-1.96zM2.718 6.235c0-.352.289-.488.767-.488.687 0 1.554.208 2.241.578V4.202a5.958 5.958 0 0 0-2.24-.415c-1.835 0-3.054.957-3.054 2.557 0 2.493 3.433 2.096 3.433 3.17 0 .416-.361.552-.867.552-.75 0-1.708-.307-2.467-.723v2.15c.84.362 1.69.515 2.467.515 1.879 0 3.17-.93 3.17-2.548-.008-2.692-3.45-2.213-3.45-3.225z"></path>
          </g>
        </svg>
      </div>
    </div>
  );
};
export default Confirmed;
